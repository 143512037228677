<div class="app-l-calendar__base">
	<div class="app-l-calendar__left">
		<div class="app-l-caledar__dates">
			<mat-calendar
				class="app-c-calendar--type2"
				[(selected)]="selectDate"
				(selectedChange)="dateSelected($event)"
			></mat-calendar>
		</div>
		<div class="app-l-calendar__schedule">
			<button
				class="app-c-btn app-c-btn--curve app-c-btn--secondary"
				(click)="scheduleActivity()"
			>
				<svg-icon icon="plus" alt="plus"></svg-icon>
				<span translate>calendar.scheduleActivity</span>
			</button>
		</div>
	</div>
	<div class="app-l-calendar__right">
		<div class="app-l-calendar-view">
			<div class="app-l-calendar__header">
				<div class="app-c-datebox">
					<button aria-label="Previous button"
						class="app-c-datebox__nav"
						(click)="onClickPrevBtn()"
					>
						<svg-icon
							icon="angle-left" alt="Previous button"
							class="w-100 h-100"
						></svg-icon>
					</button>
					<div class="app-l-summary__date-label">
						<span>
							{{ selectedDate | date : "MMM" }}
							{{ selectedDate | date : "yyyy" }}
						</span>
					</div>
					<button
						class="app-c-datebox__nav" aria-label="next button"
						(click)="onClickNextBtn()"
					>
						<svg-icon
							icon="angle-right" alt="next button"
							class="w-100 h-100"
						></svg-icon>
					</button>
				</div>
				<div
					class="app-l-calendar-choose__day"
					(click)="openDatePicker(picker)"
				>
					<h3 class="app-c-heading--h3">
						{{ selectedDate | date : "MMMM yyyy" }}
					</h3>
					<button aria-label="date picker"
						class="app-c-btn app-c-btn--icon app-c-btn--icon-20"
					>
						<svg-icon
							icon="angle-right" alt="date picker"
							class="w-100 h-100"
						></svg-icon>
					</button>
					<div class="app-l-ch__date">
						<mat-form-field>
							<input
								matInput
								[matDatepicker]="picker"
								[(ngModel)]="selectedDate"
								(dateChange)="onDateSelected($event)"
								disabled
								style="display: none"
							/>
							<mat-datepicker-toggle
								matSuffix
								[for]="picker"
								color="daily"
							>
								<mat-icon matDatepickerToggleIcon> </mat-icon>
							</mat-datepicker-toggle>
							<mat-datepicker #picker disabled="false">
								<mat-datepicker-actions>
									<button aria-label="cancel"
										class="app-c-btn app-c-btn--secondary app-c-btn--sm app-c-btn--curve"
										matDatepickerCancel
										translate
									>
										calendar.cancel
									</button>
									<button aria-label="apply"
										class="app-c-btn app-c-btn--primary app-c-btn--sm app-c-btn--curve"
										mat-raised-button
										color="primary"
										(click)="setCalendarDate()"
										matDatepickerApply
										translate
									>
										calendar.apply
									</button>
								</mat-datepicker-actions>
							</mat-datepicker>
						</mat-form-field>
					</div>
				</div>
				<div
					class="app-l-calendar__details-toggle"
					*ngIf="!showSideView"
				>
					<button aria-label="toggle Side View"
						class="app-c-btn app-c-btn--secondary app-c-btn--icon-border app-c-btn--icon-32 app-l-btn-calendar__toggle"
						(click)="toggleSideView(selectedDate, '')"
					>
						<svg-icon icon="slide-left" alt="side view"></svg-icon>
					</button>
				</div>
			</div>

			<div class="app-l-calendar__body">
				<div class="app-l-view__calendar app-l-view__monthly">
					<mwl-calendar-month-view
						[viewDate]="viewDate"
						[events]="calendarData"
						[cellTemplate]="customCellTemplate"
						class="monthly-calendar"
						(eventClicked)="
							toggleSideView($event.event.startDate, 'true')
						"
						(dayClicked)="dayClicked($event.day)"
						[headerTemplate]="headerTemplate"
					>
					</mwl-calendar-month-view>
				</div>
			</div>
			<ng-template #headerTemplate>
				<div role="row" class="cal-cell-row cal-header">
					<div
						tabindex="0"
						role="columnheader"
						class="cal-cell cal-past cal-weekend custom-cal-header"
					>
						Sun
					</div>
					<div
						tabindex="0"
						role="columnheader"
						class="cal-cell cal-past custom-cal-header"
					>
						Mon
					</div>
					<div
						tabindex="0"
						role="columnheader"
						class="cal-cell cal-past custom-cal-header"
					>
						Tue
					</div>
					<div
						tabindex="0"
						role="columnheader"
						class="cal-cell cal-past custom-cal-header"
					>
						Wed
					</div>
					<div
						tabindex="0"
						role="columnheader"
						class="cal-cell cal-today custom-cal-header"
					>
						Thu
					</div>
					<div
						tabindex="0"
						role="columnheader"
						class="cal-cell cal-future custom-cal-header"
					>
						Fri
					</div>
					<div
						tabindex="0"
						role="columnheader"
						class="cal-cell cal-future cal-weekend custom-cal-header"
					>
						Sat
					</div>
				</div>
			</ng-template>
			<ng-template #customCellTemplate let-day="day" let-locale="locale">
				<div class="cal-cell-top">
					<span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{
						day.badgeTotal
					}}</span>
					<div
						class="cal-day-number"
						[ngClass]="{ isToday: day.isToday }"
					>
						<p class="date-text">
							{{
								day.date
									| calendarDate
										: "monthViewDayNumber"
										: locale
							}}
						</p>
						<div class="calendar-content">
							<ng-container
								*ngFor="
									let event of day.events;
									let dataIndex = index
								"
								[ngSwitch]="event.category"
							>
								<div *ngIf="dataIndex <= 1">
									<div
										class="app-l-view__day-b app-l-day__activityscheduler"
										*ngSwitchCase="'activityscheduler'"
									>
										<span class="app-l-view__day-time">{{
											event.data.start
												| date : "shortTime"
										}}</span>
										<span class="app-l-view__day-title">{{
											event.data?.title
										}}</span>
									</div>
									<div
										class="app-l-view__day-b app-l-day__appointment"
										*ngSwitchCase="'appointment'"
									>
										<span class="app-l-view__day-time">{{
											event.start | date : "shortTime"
										}}</span>
										<span class="app-l-view__day-title">{{
											event.title
										}}</span>
									</div>
									<div
										class="app-l-view__day-b app-l-day__courses"
										*ngSwitchCase="'courses'"
									>
										<!-- <span class="app-l-view__day-time">{{ event.data.start | date : "shortTime" }}</span> -->
										<span class="app-l-view__day-title">{{
											event.title
										}}</span>
									</div>
									<div
										class="app-l-view__day-b app-l-day__homework"
										*ngSwitchDefault
									>
										<!-- <span class="app-l-view__day-time">{{ event.data.start | date : "shortTime" }}</span> -->
										<span class="app-l-view__day-title">{{
											event.title
										}}</span>
									</div>
								</div>
							</ng-container>
							<ng-container *ngIf="day.events.length > 2">
								<div
									class="app-l-view__day-b app-l-view__default"
								>
									<span>{{
										"+" + (day.events.length - 2) + " More"
									}}</span>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</ng-template>
		</div>
		<div *ngIf="showSideView" class="app-l-calendar__details">
			<div class="app-l-calendar__d-header">
				<div class="app-l-cl__dl">
					<h3 class="app-c-heading--h3">
						{{ selectedDate | date : "EEEE, MMM dd yyyy" }}
					</h3>
				</div>
				<div class="app-l-cl__dr">
					<button aria-label="toggle Side View"
						class="app-c-btn app-c-btn--secondary app-c-btn--icon-border app-c-btn--icon-32 app-l-btn-calendar__toggle"
						(click)="toggleSideView(selectedDate, '')"
					>
						<svg-icon icon="slide-right" alt="sideview right"></svg-icon>
					</button>
				</div>
			</div>
			<div class="app-l-calendar__d-body">
				<div class="app-l-calendar__d-opt scrollbar">
					<button aria-label="filter List"
						class="app-c-btn app-c-btn--tertiary app-c-btn--sm"
						*ngFor="let item of filterList"
						[ngClass]="{
							active: selectedFilter == item
						}"
						(click)="onSelectFilter(item)"
						translate
					>
						{{ "calendar.filter." + item }}
					</button>
				</div>
				<div class="app-l-cl__d-over">
					<div
						class="app-l-cl__d-item"
						*ngFor="let event of detailViewData; let index = index"
						[ngSwitch]="event.category"
					>
						<div
							class="app-l-cl__d-base appointment-item"
							*ngSwitchCase="'appointment'"
						>
							<div class="app-l-cl__d-left">
								<p class="sub-title" translate>
									calendar.virtualCounseling
								</p>
								<h5 class="main-title">{{ event.title }}</h5>
								<div class="scheduled-status">
									<div class="app-l-ss__icon">
										<svg-icon
											icon="clock" alt="clock"
											class="w-100 h-100"
										></svg-icon>
									</div>
									<div class="app-l-ss__cnt">
										<span class="status-time" translate
											>calendar.scheduled</span
										>
										<span>:</span>
										<span>{{
											event.start | date : "shortTime"
										}}</span>
										<span translate> calendar.to </span>
										<span>{{
											event.end | date : "shortTime"
										}}</span>
									</div>
								</div>
							</div>
							<div class="app-l-cl__d-right">
								<button aria-label="View more"
									class="app-c-btn app-c-btn--secondary app-c-btn--icon-border app-c-btn--icon-20"
								>
									<svg-icon
										icon="angle-right" alt="angle-right"
										class="w-100 h-100"
									></svg-icon>
								</button>
							</div>
						</div>
						<div
							class="app-l-cl__d-base"
							*ngSwitchCase="'activityscheduler'"
							(click)="
								onEventSelect('activityscheduler', event, index)
							"
						>
							<div class="app-l-cl__d-left">
								<p class="sub-title" translate>
									calendar.activity
								</p>
								<h5 class="main-title">
									{{ event.data?.title }}
								</h5>
								<div class="scheduled-status">
									<div class="app-l-ss__icon">
										<svg-icon
											icon="clock" alt="clock"
											class="w-100 h-100"
										></svg-icon>
									</div>
									<div class="app-l-ss__cnt">
										<span class="status-time" translate
											>calendar.scheduled</span
										>
										<span> :</span>
										<span class="app-l-ss__t-p"
											>{{
												event.data.start
													| date : "shortTime"
											}}
										</span>
										<span translate>calendar.to</span>
										<span class="app-l-ss__t-p">{{
											event.data.end | date : "shortTime"
										}}</span>
									</div>
								</div>
								<div class="completed-status">
									<div
										class="app-l-cl__complete"
										*ngIf="event.data?.status == 'complete'"
									>
										<svg-icon icon="tick-circle" alt="tick-circle"></svg-icon>
										<span translate
											>calendar.completed</span
										>
									</div>
									<button aria-label="edit"
										class="app-c-btn app-c-btn--curve app-c-btn--secondary app-c-btn--sm"
										*ngIf="
											!event.data?.status == 'complete' ||
											event.data?.status == 'inprogress'
										"
										(click)="
											openEditActivityModal(
												'activityscheduler',
												event,
												index
											)
										"
										translate
									>
										calendar.edit
									</button>
									<button aria-label="inprogress"
										class="app-c-btn app-c-btn--curve app-c-btn--secondary app-c-btn--sm"
										*ngIf="
											event.data?.status == 'inprogress'
										"
										(click)="
											setMarkAsCompleted(
												'activityscheduler',
												event,
												index
											)
										"
										translate
									>
										calendar.markAsComplete
									</button>
								</div>
							</div>
							<div class="app-l-cl__d-right">
								<button aria-label="view More"
									*ngIf="event.data?.status != 'complete'"
									class="app-c-btn app-c-btn--secondary app-c-btn--icon-border app-c-btn--icon-20"
									(click)="
										openEditActivityModal(
											'activityscheduler',
											event,
											index
										)
									"
								>
									<svg-icon
										icon="angle-right" alt="angle-right"
										class="w-100 h-100"
									></svg-icon>
								</button>
							</div>
						</div>
						<div
							class="app-l-cl__d-base"
							*ngSwitchCase="'courses'"
							(click)="onEventSelect('courses', event, index)"
						>
							<div class="app-l-cl__d-left">
								<p class="sub-title" translate>
									calendar.recommendedCourseByCounselor
								</p>
								<h5 class="main-title">{{ event.title }}</h5>
								<div class="scheduled-status">
									<div class="app-l-ss__icon">
										<svg-icon
											icon="clock" alt="clock"
											class="w-100 h-100"
										></svg-icon>
									</div>
									<div class="app-l-ss__cnt">
										<span class="status-time" translate>
											calendar.scheduled
										</span>
										<span>:</span>
										<span class="app-l-ss__t-p">{{
											event.start | date : "MMM dd yyyy"
										}}</span>
									</div>
								</div>
							</div>
							<div class="app-l-cl__d-right">
								<button
									[routerLink]="[
										'/app/series-details/' +
											(event.course_key | lowercase)
									]"
									class="app-c-btn app-c-btn--secondary app-c-btn--icon-border app-c-btn--icon-20"
								>
									<svg-icon
										icon="angle-right"  alt="angle-right"
										class="w-100 h-100"
									></svg-icon>
								</button>
							</div>
						</div>
						<div
							class="app-l-cl__d-base"
							*ngSwitchDefault
							(click)="
								onEventSelect(event.category, event, index)
							"
						>
							<div class="app-l-cl__d-left">
								<p class="sub-title" translate>
									calendar.homework
								</p>
								<h5 class="main-title">
									{{ event.title || event.typeName }}
								</h5>
								<div class="scheduled-status">
									<div class="app-l-ss__icon">
										<svg-icon
											icon="clock"  alt="clock"
											class="w-100 h-100"
										></svg-icon>
									</div>
									<div class="app-l-ss__cnt">
										<span class="status-time" translate>
											calendar.scheduled
										</span>
										<span> :</span>
										<span class="app-l-ss__t-p" translate>{{
											event.start | date : "MMM dd yyyy"
										}}</span>
									</div>
								</div>
							</div>
							<div class="app-l-cl__d-right">
								<button aria-label="View more" *ngIf="event.status && event.status != 'completed'"
									class="app-c-btn app-c-btn--secondary app-c-btn--icon-border app-c-btn--icon-20"
								>
									<svg-icon
										icon="angle-right"  alt="angle-right"
										class="w-100 h-100"
									></svg-icon>
								</button>
							</div>
						</div>
					</div>
					<div
						*ngIf="!detailViewData.length"
						class="app-l-no-mood-check"
					>
						<div class="no-data-image">
							<svg-icon icon="room-for-positive" alt="room-for-positive"></svg-icon>
							<span
								class="app-l-no-mood__round app-l-no-mood__round1"
							></span>
							<span
								class="app-l-no-mood__round app-l-no-mood__round2"
							></span>
							<span
								class="app-l-no-mood__round app-l-no-mood__round3"
							></span>
						</div>
						<h5 class="app-c-body-text--16-m" translate>
							calendar.noDataFound
						</h5>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
