import {
	Component,
	ElementRef,
	EventEmitter,
	OnInit,
	Output,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../lib/api.service";
import { LogService } from "../../lib/log.service";
import { UserService } from "../../lib/user.service";
import { Moodcheck } from "../../models/moodcheck";
import { User } from "../../models/user";
import * as moment from "moment";
import * as _ from "lodash";
import {
	MatDatepicker,
	MatDatepickerInputEvent,
} from "@angular/material/datepicker";
import { UtilityService } from "app/lib/utility.service";
import { DatePipe } from "@angular/common";
import { Subject } from "rxjs";
import { ModalService } from "app/lib/modal.service";
import { MatCalendarCellClassFunction } from "@angular/material/datepicker";
import { ActivitySchedulerDetails } from "../../modules/practice/activity-scheduler-details/activity-scheduler-details";
import { Event } from "../../models/event";
import { CalendarEvent } from "angular-calendar";
import { TranslateService } from "@ngx-translate/core";
import { AnalyticEvent } from "app/lib/analytic-event";
@Component({
	selector: "app-daily-calendar-view",
	templateUrl: "./daily-calendar-view.component.html",
	styleUrls: ["./daily-calendar-view.component.scss"],
	providers: [DatePipe],
})
export class DailyCalendarViewComponent implements OnInit {
	@Output() emitDailyViewData = new EventEmitter<any>();
	today: any = moment.parseZone().startOf("day").format();
	moodcheckListing: ElementRef;
	calendarData: Moodcheck[];
	stagger: boolean = true;
	slideDirection: string;
	weekOfYear: any;
	monthOfYear: any;
	currentDate: any;
	user: User;
	weekDaysWithData: any = [];
	selectedDate: any = moment.parseZone().startOf("day").format();
	displayDate: any = moment().format("MMMM YYYY");
	weekDate: any = moment.parseZone().startOf("day").format();
	currentDateData: any = [];
	detailViewData: any = [];
	isLoading: boolean = true;
	selectedFilter: string = "all";
	filterList = [
		"all",
		"activityscheduler",
		"courses",
		"appointment",
		"homework",
	];
	startOfWeek: any;
	endOfWeek: any;
	viewDate: Date = new Date();
	// selectedDateFormated: Date = new Date();
	refresh: Subject<void> = new Subject<void>();
	//dayStartHour = 6;
	showSideView: boolean = false;
	currentDateDataSideView: any[];
	calendarList: Event[];
	dateFilteredEvnets: any = [];
	dataNoFilter: any;
	selectDate: MatCalendarCellClassFunction<Date>;
	selectedDayTitle: string;
	isToday: boolean = true;
	dashboardLangKey: any;
	todayDate: any = moment.parseZone().format();
	duration: number;

	constructor(
		private api: ApiService,
		private userService: UserService,
		private log: LogService,
		private router: Router,
		private datePipe: DatePipe,
		private modalService: ModalService,
		private translate: TranslateService
	) {
		this.user = this.userService.getUser();
		this.translate.stream("dashboard").subscribe((res: any) => {
			this.dashboardLangKey = res;
			this.selectedDayTitle = this.dashboardLangKey.today;
		});
	}

	ngOnInit(): void {
		this.isToday = this.checkToday(this.todayDate);
		this.selectDate = this.todayDate;
	}

	scheduleActivity() {
		this.modalService
			.showComponent(ActivitySchedulerDetails, {}, "app-c-modal--cure")
			.afterClosed()
			.subscribe((result) => {
				this.fetchEvents();
			});
	}

	fetchEvents(): void {
		this.api.get("calendar/all", { creatorView: true }).subscribe(
			(result: any) => {
				const events = Event.initializeArray(result.data, true);
				if (this.user.userType === "user") {
					events.forEach((eventClass, index) => {
						eventClass["cssClass"] = "";
					});
				}
				this.log.event(AnalyticEvent.event.calendarLoad);
				let dateNotFilteredEvnets = (this.dataNoFilter = events);
				let dateFilteredEvnets = this.generateDateObjectOfEvents();
				let currentDate = moment.parseZone().startOf("day").format();
				this.updateDailyCalendarView(
					dateFilteredEvnets,
					dateNotFilteredEvnets,
					currentDate
				);
			},
			(error: any) => {
				this.log.error(
					"Error getting calendar events." + error.message
				);
			},
			() => {}
		);
		this.refresh.next();
	}

	updateDailyCalendarView(
		data: any = [],
		dataNoFilter: any = [],
		currentDate?: any
	) {
		this.isLoading = false;
		this.selectedDate = currentDate;
		this.viewDate = UtilityService.convertToDate(currentDate);

		this.isToday = this.checkToday(this.todayDate, this.selectedDate);
		this.selectedDayTitle = !this.isToday
			? this.dashboardLangKey.days[moment(this.selectedDate).day()]
			: this.dashboardLangKey.today;
		this.calendarData = data;
		this.dataNoFilter = dataNoFilter;
		this.dataNoFilter.forEach((element) => {
			let startTime = moment.parseZone(element.start);
			let endTime = moment.parseZone(element.end);
			element["duration"] = moment
				.duration(endTime.diff(startTime))
				.asMinutes();
		});
		this.currentDateDataSideView = data;

		this.refresh.next();
		this.startOfWeek = this.getStartOfWeek(new Date(this.selectedDate));
		this.dateFilteredEvnets = this.generateDateObjectOfEvents();
		this.setCurrentDateData();
		this.setCurrentDateDataSideView();
	}

	checkToday(date: Date, currentDate?: Date): boolean {
		const today = currentDate
			? moment(currentDate).startOf("day")
			: moment().startOf("day");
		const inputDate = moment(date).startOf("day");
		return today.isSame(inputDate);
	}

	dateSelected(event): void {
		this.selectDate = event;
		this.updateDailyCalendarView(
			this.calendarData,
			this.dataNoFilter,
			event
		);
	}

	generateDateObjectOfEvents() {
		let dateObjOfEvents = {};
		for (let i = 0; i < this.dataNoFilter.length; i++) {
			let eventStartDate = moment
				.parseZone(this.dataNoFilter[i].start)
				.format("L");
			if (!dateObjOfEvents[eventStartDate]) {
				dateObjOfEvents[eventStartDate] = {
					data: [],
				};
			}
			dateObjOfEvents[eventStartDate].data.push(this.dataNoFilter[i]);
		}
		return dateObjOfEvents;
	}

	generateDateObjectOfCurrentDateDataSideView() {
		let dateObjOfEvents = {};
		for (let i = 0; i < this.currentDateDataSideView.length; i++) {
			let eventStartDate = moment
				.parseZone(this.currentDateDataSideView[i].start)
				.format("L");
			if (!dateObjOfEvents[eventStartDate]) {
				dateObjOfEvents[eventStartDate] = {
					data: [],
				};
			}
			dateObjOfEvents[eventStartDate].data.push(
				this.currentDateDataSideView[i]
			);
		}
		return dateObjOfEvents;
	}

	setCurrentDateData() {
		this.currentDateData = [];
		let data = this.calendarData[moment(this.selectedDate).format("L")]
			? this.calendarData[moment(this.selectedDate).format("L")].data
			: [];
		if (this.selectedFilter != "all") {
			if (
				["activityscheduler", "courses", "appointment"].includes(
					this.selectedFilter
				)
			) {
				this.currentDateData = _.filter(data, {
					category: this.selectedFilter as any,
				});
			} else {
				this.currentDateData = data.filter(
					(item) =>
						![
							"activityscheduler",
							"courses",
							"appointment",
						].includes(item.category)
				);
			}
		} else {
			data.forEach((item) => {
				if (item.data) {
					this.currentDateData.push(item.data);
				}
			});
		}
		this.refresh.next();
	}

	setCurrentDateDataSideView() {
		this.currentDateDataSideView = [];
		let data = this.calendarData[moment(this.selectedDate).format("L")]
			? this.calendarData[moment(this.selectedDate).format("L")].data
			: [];
		if (this.selectedFilter != "all") {
			if (
				["activityscheduler", "courses", "appointment"].includes(
					this.selectedFilter
				)
			) {
				this.currentDateDataSideView = _.filter(data, {
					category: this.selectedFilter as any,
				});
			} else {
				this.currentDateDataSideView = data.filter(
					(item) =>
						![
							"activityscheduler",
							"courses",
							"appointment",
						].includes(item.category)
				);
			}
		} else {
			this.currentDateDataSideView = data;
		}
		this.refresh.next();
	}

	onEventSelect(eventType: string, event: any, index: number) {
		if(event.status != 'completed'){
			switch (eventType) {
				case "activityscheduler": {
					// this.openEditActivityModal(event, index);
					break;
				}
				case "assessment":
				case "brcs":
				case "mentalwellbeing":
				case "gad-7":
				case "audit":
				case "phq-9":	 {
					this.goToAssessment(eventType);
					break;
				}
				case "courses": {
					this.goToCourse(event.courseKey);
					break;
				}
				case "moodcheck": {
					this.goToMoodCheck();
					break;
				}
				case "zenroom": {
					this.goToZen();
					break;
				}
				case "quiz": {
					this.goToQuiz();
					break;
				}
				case "funachievement": {
					this.goToFun();
					break;
				}
				case "thoughtdiary": {
					this.goToThought();
					break;
				}
			}
		}
	}

	goToMoodCheck() {
		this.router.navigate(["/app/mood-check"]);
	}

	goToCourse(courseKey) {
		this.router.navigateByUrl("/app/series-details/" + courseKey);
	}

	goToAssessment(assessment: string) { 
		let route: string;

		switch (assessment) {
			case "assessment":
				route = "/app/assessments/dass/new";
				break;
			case "phq-9":
				route = "/app/assessments/depression/new";
				break;
			case "audit":
				route = "/app/assessments/alcoholuse/new";
				break;
			case "gad-7":
				route = "/app/assessments/anxiety/new";
				break;
			case "mentalwellbeing":
				route = "/app/wellbeing-assessments/mentalwellbeing/new";
				break;
			case "brcs":
				route = "/app/assessments/resilience/new";
				break;
			default:
				route = "/app/assessments/listing";
		}
	
		this.router.navigate([route]);
	}

	goToZen() {
		this.router.navigate(["/app/practice/zen-room"]);
	}

	goToThought() {
		this.router.navigate(["/app/practice/thought-diary"]);
	}

	goToQuiz() {
		this.router.navigate(["/app/practice/cd-quiz"]);
	}

	goToFun() {
		this.router.navigate(["/app/practice/funachievement"]);
	}

	disableTooltip(event: MouseEvent) {
		event.preventDefault();
	}

	getWeekDaysData(data: any, date: any) {
		var weekDays = [];
		var dayOfWeek = moment(date).startOf("week");
		for (var i = 0; i < 7; i++) {
			weekDays.push({
				weekDay: dayOfWeek.format("dd"),
				shortDate: dayOfWeek.format("DD"),
				date: dayOfWeek.parseZone().format(),
				data: data[dayOfWeek.format("L")]
					? data[dayOfWeek.format("L")].data
					: [],
			});
			dayOfWeek.add(1, "d");
		}
		return weekDays;
	}

	setCalendarDate() {
		this.startOfWeek = this.getStartOfWeek(new Date(this.selectedDate));
		this.updateDailyCalendarView(
			this.calendarData,
			this.dataNoFilter,
			this.selectedDate
		);
	}

	openDatePicker(picker: MatDatepicker<Date>) {
		picker.open();
	}

	onDateSelected(event: MatDatepickerInputEvent<Date>) {
		this.selectedDate = event.value;
	}

	onClickNextBtn() {
		if (this.showSideView) {
			this.showSideView = false;
		}
		let date = moment(this.selectedDate).add(1, "days").format();
		this.selectedDate = date;

		this.viewDate = UtilityService.convertToDate(
			moment(date).format("YYYY-MM-DD")
		);
		this.selectDate = this.selectedDate;
		this.startOfWeek = this.getStartOfWeek(new Date(this.selectedDate));
		this.updateDailyCalendarView(
			this.calendarData,
			this.dataNoFilter,
			this.selectedDate
		);
	}

	onClickPrevBtn() {
		if (this.showSideView) {
			this.showSideView = false;
		}
		let date = moment(this.selectedDate).add(-1, "days").format();
		this.selectedDate = date;
		this.viewDate = UtilityService.convertToDate(
			moment(date).format("YYYY-MM-DD")
		);
		this.selectDate = this.selectedDate;
		this.startOfWeek = this.getStartOfWeek(this.selectedDate);
		this.updateDailyCalendarView(
			this.calendarData,
			this.dataNoFilter,
			this.selectedDate
		);
	}

	getStartOfWeek(date: Date): string {
		const startOfWeek = new Date(date);
		return this.datePipe.transform(startOfWeek, "mediumDate");
	}

	toggleSideView() {
		this.showSideView = !this.showSideView;
	}

	onSelectFilter(type) {
		this.selectedFilter = type;
		this.setCurrentDateDataSideView();
	}

	async openEditActivityModal(type, eventData: any, itemIndex: number) {
		this.modalService
			.showComponent(
				ActivitySchedulerDetails,
				{ activityId: eventData.data.id },
				"app-c-modal--cure",
				false,
				eventData.data.id ? "400px" : ""
			)
			.afterClosed()
			.subscribe((data) => {
				if (data) {
					switch (data.action) {
						case "save": {
							const inputDate = moment(
								data.activityData.start
							).startOf("day");
							let currentDateDataSideViewFormatArr =
								this.generateDateObjectOfCurrentDateDataSideView();
							let event =
								currentDateDataSideViewFormatArr[
									moment(this.selectedDate).format("L")
								].data[itemIndex];
							const calendarIndex = this.dataNoFilter.findIndex(
								(item) => item.id === event.id
							);
							if (calendarIndex !== -1) {
								this.dataNoFilter[calendarIndex].data =
									data.activityData;
								this.dataNoFilter[calendarIndex].end =
									data.activityData.end;
								this.dataNoFilter[calendarIndex].start =
									data.activityData.start;
								this.dataNoFilter[calendarIndex].startDate =
									data.activityData.start;
							}
							event.data = data.activityData;
							event.start = data.activityData.start;
							event.startDate = data.activityData.start;

							if (
								!moment(this.selectedDate)
									.startOf("day")
									.isSame(inputDate)
							) {
								this.calendarData[
									moment(this.selectedDate).format("L")
								].data.splice(itemIndex, 1);
								if (
									!this.calendarData[
										moment(inputDate).format("L")
									]
								) {
									this.calendarData[
										moment(inputDate).format("L")
									] = {
										data: [],
									};
								}
								this.calendarData[
									moment(inputDate).format("L")
								].data.push(event);
							}
							break;
						}
						case "delete": {
							this.calendarData[
								moment(this.selectedDate).format("L")
							].data.splice(itemIndex, 1);
							this.refresh.next();
							this.updateCalendarList();
							break;
						}
					}
					this.refresh.next();
				}
			});
	}

	async setMarkAsCompleted(type, eventData: any, itemIndex: number) {
		this.modalService
			.showConfirmation(
				"Mark as complete",
				"Are you sure you want to mark this activity as completed?"
			)
			.afterClosed()
			.subscribe((data) => {
				if (data) {
					this.api
						.post(
							`practice/activityscheduler/updatestatus/${eventData.data.id}?Status=complete`,
							""
						)
						.subscribe(
							(result: any) => {
								if (result) {
									this.updateCalendarList(
										eventData,
										itemIndex,
										result
									);
								}
							},
							(error: any) => {
								this.log.error("Error in updating status. ");
							}
						);
				}
			});
	}
	updateCalendarList(eventData?: any, itemIndex?: number, result?) {
		this.api
			.get("calendar/all", { creatorView: true })
			.subscribe((listData: any) => {
				this.calendarList = Event.initializeArray(listData.data, true);
				this.dataNoFilter = this.calendarList;

				if (result) {
					this.log.event(AnalyticEvent.event.markAsCompleteActivity);
					const calendarIndex = this.calendarList.findIndex(
						(item) => item.id === eventData.data.id
					);
					if (calendarIndex !== -1) {
						let currentDateDataSideViewFormatArr =
							this.generateDateObjectOfCurrentDateDataSideView();
						let event =
							currentDateDataSideViewFormatArr[
								moment(this.selectedDate).format("L")
							].data[itemIndex];

						event.data.status =
							this.calendarList[calendarIndex].data.status;
						this.modalService.showAlert("Success", result.message);
					}
				}
			});
	}
}
