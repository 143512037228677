<div class="app-l-calendar__base">
	<div class="app-l-calendar__left">
		<div class="app-l-caledar__dates">
			<mat-calendar
				class="app-c-calendar--type2"
				[(selected)]="selectDate"
				(selectedChange)="dateSelected($event)"
			></mat-calendar>
		</div>
		<div class="app-l-calendar__schedule">
			<button aria-label="Schedule Activity"
				class="app-c-btn app-c-btn--curve app-c-btn--secondary"
				(click)="scheduleActivity()"
			>
				<svg-icon icon="plus" alt="plus"></svg-icon>
				<span translate>calendar.scheduleActivity</span>
			</button>
		</div>
	</div>
	<div class="app-l-calendar__right">
		<div class="app-l-calendar-view">
			<div class="app-l-calendar__header">
				<div class="app-c-datebox">
					<button aria-label="Previous Button"
						class="app-c-datebox__nav"
						(click)="onClickPrevBtn()"
					>
						<svg-icon alt="Previous Button"
							icon="angle-left"
							class="w-100 h-100"
						></svg-icon>
					</button>
					<div class="app-l-summary__date-label">
						<span>{{ displayDate + ", Week " + weekNumber }}</span>
						<span>
							<!-- {{ startOfWeek | date: 'MMM dd'  }} - {{ endOfWeek | date: 'MMM dd' }} ,{{ endOfWeek | date: 'yyyy' }}  -->
						</span>
					</div>
					<button  aria-label="next Button"
						class="app-c-datebox__nav"
						(click)="onClickNextBtn()"
					>
						<svg-icon alt="next Button"
							icon="angle-right"
							class="w-100 h-100"
						></svg-icon>
					</button>
				</div>
				<div
					class="app-l-calendar-choose__day"
					(click)="openDatePicker(picker)"
				>
					<h3 class="app-c-heading--h3">
						{{ startOfWeek | date : "MMM dd" }} -
						{{ endOfWeek | date : "MMM dd" }} ,
						{{ endOfWeek | date : "yyyy" }}
					</h3>
					<button  aria-label="date picker"
						class="app-c-btn app-c-btn--icon app-c-btn--icon-20"
					>
						<svg-icon
							icon="angle-right" alt="angle-right"
							class="w-100 h-100"
						></svg-icon>
					</button>
					<div class="app-l-ch__date">
						<mat-form-field>
							<input
								matInput
								[matDatepicker]="picker"
								[(ngModel)]="selectedDate"
								(dateChange)="onDateSelected($event)"
								disabled
								style="display: none"
							/>
							<mat-datepicker-toggle
								matSuffix
								[for]="picker"
								color="daily"
							>
								<mat-icon matDatepickerToggleIcon> </mat-icon>
							</mat-datepicker-toggle>
							<mat-datepicker #picker disabled="false">
								<mat-datepicker-actions>
									<button aria-label="cancel"
										class="app-c-btn app-c-btn--secondary app-c-btn--sm app-c-btn--curve"
										matDatepickerCancel
										translate
									>
										calendar.cancel
									</button>
									<button aria-label="apply"
										class="app-c-btn app-c-btn--primary app-c-btn--sm app-c-btn--curve"
										mat-raised-button
										color="primary"
										(click)="setCalendarDate()"
										matDatepickerApply
										translate
									>
										calendar.apply
									</button>
								</mat-datepicker-actions>
							</mat-datepicker>
						</mat-form-field>
					</div>
				</div>
				<div
					class="app-l-calendar__details-toggle"
					*ngIf="!showSideView"
				>
					<button aria-label="toggle Side View"
						class="app-c-btn app-c-btn--secondary app-c-btn--icon-border app-c-btn--icon-32 app-l-btn-calendar__toggle"
						(click)="toggleSideView(selectedDate, '')"
					>
						<svg-icon icon="slide-left" alt="slide left"></svg-icon>
					</button>
				</div>
			</div>
			<div class="app-l-calendar__body">
				<div class="app-l-view__calendar app-l-view__weekly">
					<mwl-calendar-week-view
						[viewDate]="viewDate"
						[draggable]="false"
						[events]="calendarData"
						[headerTemplate]="headerTemplate"
						[refresh]="refresh"
						[eventTemplate]="templatePlan"
						[tooltipTemplate]=""
						(mouseOver)="disableTooltip($event)"
						(eventClicked)="
							toggleSideView($event.event.startDate, 'true')
						"
					>
					</mwl-calendar-week-view>
				</div>
			</div>
			<ng-template let-days="days" #headerTemplate let-locale="locale">
				<div role="row" class="cal-cell-row cal-header">
					<div
						tabindex="0"
						role="columnheader"
						class="cal-cell cal-past cal-weekend custom-cal-header"
						*ngFor="let day of days"
						[ngClass]="{
							'cal-today':
								(day.date | date : 'yyyy-MM-dd') ===
								(today | date : 'yyyy-MM-dd')
						}"
					>
						<span class="app-l-week-name">{{
							day.date | date : "E"
						}}</span>
						<span class="app-l-week-day">{{
							day.date
								| calendarDate : "monthViewDayNumber" : locale
						}}</span>
					</div>
				</div>
			</ng-template>
			<ng-template
				let-weekEvent="weekEvent"
				#templatePlan
				let-locale="locale"
			>
				<div
					class="app-l-view__day-b"
					[ngClass]="{
						'app-l-day__appointment':
							weekEvent.event.category === 'appointment',
						'app-l-day__activityscheduler':
							weekEvent.event.category === 'activityscheduler',
						'app-l-day__courses':
							weekEvent.event.category === 'courses',
						'app-l-day__homework':
							weekEvent.event.category !== 'appointment' &&
							weekEvent.event.category !== 'activityscheduler' &&
							weekEvent.event.category !== 'courses'
					}"
					(click)="toggleSideView(weekEvent.event.startDate, 'true')"
				>
					<span
						class="app-l-view__day-name"
						*ngIf="weekEvent.event.category === 'appointment'"
						>{{ weekEvent.event.category | titlecase }}</span
					>
					<span
						class="app-l-view__day-name"
						*ngIf="weekEvent.event.category === 'courses'" translate
						>calendar.filter.courses</span
					>
					<span
						class="app-l-view__day-name"
						*ngIf="weekEvent.event.category === 'activityscheduler'" translate
						>calendar.filter.activityscheduler</span
					>
					<span
						class="app-l-view__day-name"
						*ngIf="
							weekEvent.event.category !== 'appointment' &&
							weekEvent.event.category !== 'activityscheduler' &&
							weekEvent.event.category !== 'courses'
						" translate
						>calendar.filter.homework</span
					>
					<span class="app-l-view__day-title">{{
						weekEvent.event.title
					}}</span>
				</div>
			</ng-template>
		</div>
		<div *ngIf="showSideView" class="app-l-calendar__details">
			<div class="app-l-calendar__d-header">
				<div class="app-l-cl__dl">
					<h3 class="app-c-heading--h3">
						{{ selectedDate | date : "EEEE, MMM dd yyyy" }}
					</h3>
				</div>
				<div class="app-l-cl__dr">
					<button aria-label="toggle Side View"
						class="app-c-btn app-c-btn--secondary app-c-btn--icon-border app-c-btn--icon-32 app-l-btn-calendar__toggle"
						(click)="toggleSideView()"
					>
						<svg-icon icon="slide-right" alt="slide-right"></svg-icon>
					</button>
				</div>
			</div>
			<div class="app-l-calendar__d-body">
				<div class="app-l-calendar__d-opt scrollbar">
					<button aria-label="item"
						*ngFor="let item of filterList"
						class="app-c-btn app-c-btn--tertiary app-c-btn--sm"
						[ngClass]="{
							active: selectedFilter == item
						}"
						(click)="onSelectFilter(item)"
						translate
					>
						{{ "calendar.filter." + item }}
					</button>
				</div>
				<div class="app-l-cl__d-over">
					<div
						class="app-l-cl__d-item"
						*ngFor="let event of detailViewData; let index = index"
						[ngSwitch]="event.category"
					>
						<div
							class="app-l-cl__d-base"
							*ngSwitchCase="'appointment'"
						>
							<div class="app-l-cl__d-left">
								<p class="sub-title" translate>
									calendar.virtualCounseling
								</p>
								<h5 class="main-title">{{ event.title }}</h5>
								<div class="scheduled-status">
									<div class="app-l-ss__icon">
										<svg-icon alt="clock"
											icon="clock"
											class="w-100 h-100"
										></svg-icon>
									</div>
									<div class="app-l-ss__cnt">
										<span class="status-time" translate
											>calendar.scheduled</span
										>
										<span>:</span>
										<span>{{
											event.start | date : "shortTime"
										}}</span>
										<span translate> calendar.to </span>
										<span>{{
											event.end | date : "shortTime"
										}}</span>
									</div>
								</div>
							</div>
							<div class="app-l-cl__d-right">
								<button aria-label="view-more-button"
									class="app-c-btn app-c-btn--secondary app-c-btn--icon-border app-c-btn--icon-20"
								>
									<svg-icon alt="angle-right"
										icon="angle-right"
										class="w-100 h-100"
									></svg-icon>
								</button>
							</div>
						</div>
						<div
							class="app-l-cl__d-base"
							*ngSwitchCase="'activityscheduler'"
							(click)="
								onEventSelect('activityscheduler', event, index)
							"
						>
							<div class="app-l-cl__d-left">
								<p class="sub-title" translate>
									calendar.activity
								</p>
								<h5 class="main-title">
									{{ event.data?.title }}
								</h5>
								<div class="scheduled-status">
									<div class="app-l-ss__icon">
										<svg-icon
											icon="clock"
											class="w-100 h-100"
										></svg-icon>
									</div>
									<div class="app-l-ss__cnt">
										<span class="status-time" translate
											>calendar.scheduled</span
										>
										<span> :</span>
										<span class="app-l-ss__t-p"
											>{{
												event.data.start
													| date : "shortTime"
											}}
										</span>
										<span translate>calendar.to</span>
										<span class="app-l-ss__t-p">{{
											event.data.end | date : "shortTime"
										}}</span>
									</div>
								</div>
								<div class="completed-status">
									<div
										class="app-l-cl__complete"
										*ngIf="event.data?.status == 'complete'"
									>
										<svg-icon icon="tick-circle"></svg-icon>
										<span translate
											>calendar.completed</span
										>
									</div>
									<button
										class="app-c-btn app-c-btn--curve app-c-btn--secondary app-c-btn--sm"
										*ngIf="
											!event.data?.status == 'complete' ||
											event.data?.status == 'inprogress'
										"
										(click)="
											openEditActivityModal(
												'activityscheduler',
												event,
												index
											)
										"
										translate
									>
										calendar.edit
									</button>
									<button
										class="app-c-btn app-c-btn--curve app-c-btn--secondary app-c-btn--sm"
										(click)="
											setMarkAsCompleted(
												'activityscheduler',
												event,
												index
											)
										"
										*ngIf="
											event.data?.status == 'inprogress'
										"
										translate
									>
										calendar.markAsComplete
									</button>
								</div>
							</div>
							<div class="app-l-cl__d-right">
								<button aria-label="view-more-button"
									*ngIf="event.data?.status != 'complete'"
									class="app-c-btn app-c-btn--secondary app-c-btn--icon-border app-c-btn--icon-20"
									(click)="
										openEditActivityModal(
											'activityscheduler',
											event,
											index
										)
									"
								>
									<svg-icon alt="angle-right"
										icon="angle-right"
										class="w-100 h-100"
									></svg-icon>
								</button>
							</div>
						</div>
						<div
							class="app-l-cl__d-base"
							*ngSwitchCase="'courses'"
							(click)="onEventSelect('courses', event, index)"
						>
							<div class="app-l-cl__d-left">
								<p class="sub-title" translate>
									calendar.recommendedCourseByCounselor
								</p>
								<h5 class="main-title">{{ event.title }}</h5>
								<div class="scheduled-status">
									<div class="app-l-ss__icon">
										<svg-icon
											icon="clock"
											class="w-100 h-100"
										></svg-icon>
									</div>
									<div class="app-l-ss__cnt">
										<span class="status-time" translate>
											calendar.scheduled
										</span>
										<span>:</span>
										<span class="app-l-ss__t-p">{{
											event.start | date : "MMM dd yyyy"
										}}</span>
									</div>
								</div>
							</div>
							<div class="app-l-cl__d-right">
								<button aria-label="view-more-button"
									[routerLink]="[
										'/app/series-details/' +
											(event.course_key | lowercase)
									]"
									class="app-c-btn app-c-btn--secondary app-c-btn--icon-border app-c-btn--icon-20"
								>
									<svg-icon alt="angle-right"
										icon="angle-right"
										class="w-100 h-100"
									></svg-icon>
								</button>
							</div>
						</div>
						<div
							class="app-l-cl__d-base"
							*ngSwitchDefault
							(click)="
								onEventSelect(event.category, event, index)
							"
						>
							<div class="app-l-cl__d-left">
								<p class="sub-title" translate>
									calendar.homework
								</p>
								<h5 class="main-title">
									{{ event.title || event.typeName }}
								</h5>
								<div class="scheduled-status">
									<div class="app-l-ss__icon">
										<svg-icon
											icon="clock"
											class="w-100 h-100"
										></svg-icon>
									</div>
									<div class="app-l-ss__cnt">
										<span class="status-time" translate>
											calendar.scheduled
										</span>
										<span> :</span>
										<span class="app-l-ss__t-p" translate>{{
											event.start | date : "MMM dd yyyy"
										}}</span>
									</div>
								</div>
							</div>
							<div class="app-l-cl__d-right">
								<button aria-label="view-more-button" *ngIf="event.status && event.status != 'completed'"
									class="app-c-btn app-c-btn--secondary app-c-btn--icon-border app-c-btn--icon-20"
								>
									<svg-icon alt="angle-right"
										icon="angle-right"
										class="w-100 h-100"
									></svg-icon>
								</button>
							</div>
						</div>
					</div>
					<div
						*ngIf="!detailViewData.length"
						class="app-l-no-mood-check"
					>
						<div class="no-data-image">
							<svg-icon icon="room-for-positive"></svg-icon>
							<span
								class="app-l-no-mood__round app-l-no-mood__round1"
							></span>
							<span
								class="app-l-no-mood__round app-l-no-mood__round2"
							></span>
							<span
								class="app-l-no-mood__round app-l-no-mood__round3"
							></span>
						</div>
						<h5 class="app-c-body-text--16-m" translate>
							calendar.noDataFound
						</h5>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
