import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'app/lib/api.service';
import { COMMON_CONFIG } from 'app/lib/constants';
import { ModalService } from 'app/lib/modal.service';
import { PowerbiService } from 'app/lib/powerbi.service';
import { UserService } from 'app/lib/user.service';
import { User } from 'app/models/user';
import * as moment from 'moment';
import * as powerbi from 'powerbi-client';
@Component({
    selector: 'app-powerbi',
    templateUrl: './powerbi.component.html',
    styleUrls: ['./powerbi.component.scss'],
})
export class PowerbiComponent implements OnInit {
    user: User;
    @Input() orgId: User;
    private report: any;
    private tokenObject: any;
    private exportId: any;
    private reportTitle: string;
    showButton: boolean = false;
    isExportInProgress = this.powerBIService.exportInProgress$;
    constructor(
        private powerBIService: PowerbiService,
        private userService: UserService,
        private modalService: ModalService,
        private translate: TranslateService
    ) {
        this.user = this.userService.getUser();
    }

    ngOnInit(): void {
        this.embedPowerBiReport();
    }

    embedPowerBiReport() {
        const payLoad = this.orgId ? { orgreport: 1 } : {};
        this.powerBIService.getEmbedToken(payLoad).subscribe(response => {
            this.tokenObject = response;
            const embedConfig = {
                type: 'report',
                embedUrl: this.powerBIService.generateEmbedUrl(
                    this.tokenObject,
                    this.user
                ),
                accessToken: this.tokenObject?.embedToken,
                tokenType: powerbi.models.TokenType.Embed,
                viewMode: powerbi.models.ViewMode.View,
                settings: {
                    filterPaneEnabled: false,
                    navContentPaneEnabled: false,
                    hideErrors: true,
                },
                filters: this.getFilters(),
            };
            const reportContainer = document.getElementById('reportContainer');
            const powerBIClient = new powerbi.service.Service(
                powerbi.factories.hpmFactory,
                powerbi.factories.wpmpFactory,
                powerbi.factories.routerFactory
            );
            this.report = powerBIClient.embed(reportContainer, embedConfig);
            this.report.on('loaded', () => {
                this.showButton = true;
            });
            this.report.on('error', event => {
                let errorMessage =
                    event?.detail?.detailedMessage ||
                    this.translate.instant('error.unexpectedError');
                // let errorCode = event.detail.errorCode || 'UnknownError';
                this.modalService.showAlert(
                    this.translate.instant('error.title'),
                    errorMessage
                );
            });
        });
    }

    getFilters() {
        const filters = [];

        if (this.user.userType === 'orgadmin') {
            filters.push(
                this.powerBIService.createFilter('user_view', 'Email', [
                    this.user.email,
                ])
            );
            this.reportTitle = COMMON_CONFIG.POWER_BI_REPORT_TYPES.clientReport;
        } else {
            if (this.orgId) {
                filters.push(
                    this.powerBIService.createFilter(
                        'view_organization',
                        'ID',
                        [this.orgId]
                    )
                );
            }
            this.reportTitle = this.orgId
                ? COMMON_CONFIG.POWER_BI_REPORT_TYPES.organizationReport
                : COMMON_CONFIG.POWER_BI_REPORT_TYPES.adminReport;
        }

        return filters;
    }

    async downloadReportAsPDF() {
        this.powerBIService.setExportState(true);
        try {
            const options = {
                personalizeVisuals: true,
                allPages: true,
            };
            const bookmarkState =
                await this.report.bookmarksManager.capture(options);
            this.powerBIService
                .exportPdf(this.tokenObject, bookmarkState)
                .then(response => {
                    if (response) {
                        this.exportId = response.id;
                        this.pollForReportStatus();
                    }
                })
                .catch(error => {
                    this.powerBIService.setExportState(false);
                });
        } catch (error) {
            console.error('Error during report export:', error);
            this.powerBIService.setExportState(false);
        }
    }

    pollForReportStatus() {
        const POLL_INTERVAL = 5000;

        const checkStatus = () => {
            this.powerBIService
                .checkReportGenerated(this.exportId, this.tokenObject, false)
                .then(exportResponse => {
                    if (exportResponse.status === 'Succeeded') {
                        this.downloadReport();
                    } else {
                        setTimeout(checkStatus, POLL_INTERVAL);
                    }
                })
                .catch(error => {
                    this.powerBIService.setExportState(false);
                });
        };

        checkStatus();
    }

    downloadReport() {
        this.powerBIService
            .checkReportGenerated(this.exportId, this.tokenObject, true)
            .then((file: Blob) => {
                // Trigger file download
                const link = document.createElement('a');
                const url = URL.createObjectURL(file);
                link.href = url;
                link.download =
                    this.reportTitle +
                    '_' +
                    moment().format('MM-DD-YYYY_HH.mm.ss') +
                    '.pdf';
                link.click();
                URL.revokeObjectURL(url);
                this.powerBIService.setExportState(false);
            })
            .catch(error => {
                this.powerBIService.setExportState(false);
            });
    }
}
