<div class="app-l-calendar__base">
	<div class="app-l-calendar__left">
		<div class="app-l-caledar__dates">
			<mat-calendar
				class="app-c-calendar--type2"
				[(selected)]="selectDate"
				(selectedChange)="dateSelected($event)"
			></mat-calendar>
		</div>
		<div class="app-l-calendar__schedule">
			<button aria-label="schedule Activity"
				class="app-c-btn app-c-btn--curve app-c-btn--secondary"
				(click)="scheduleActivity()"
			>
				<svg-icon icon="plus" alt="plus"></svg-icon>
				<span translate>calendar.scheduleActivity</span>
			</button>
		</div>
	</div>
	<div class="app-l-calendar__right">
		<div class="app-l-calendar-view">
			<div class="app-l-calendar__header">
				<div class="app-c-datebox">
					<button aria-label="Previous button"
						class="app-c-datebox__nav"
						(click)="onClickPrevBtn()"
					>
						<svg-icon alt="angle-left"
							icon="angle-left"
							class="w-100 h-100"
						></svg-icon>
					</button>
					<div class="app-l-summary__date-label">
						<span>
							<span>{{ selectedDayTitle }}</span>
						</span>
					</div>
					<button aria-label="next button"
						class="app-c-datebox__nav"
						(click)="onClickNextBtn()"
					>
						<svg-icon alt="angle-right"
							icon="angle-right"
							class="w-100 h-100"
						></svg-icon>
					</button>
				</div>

				<div
					*ngIf="startOfWeek"
					class="app-l-calendar-choose__day"
					(click)="openDatePicker(picker)"
				>
					<h3 class="app-c-heading--h3">
						{{ startOfWeek | date : "MMMM dd" }} ,
						{{ startOfWeek | date : "yyyy" }}
					</h3>
					<button aria-label="open Date Picker"
						class="app-c-btn app-c-btn--icon app-c-btn--icon-20"
					>
						<svg-icon alt="angle-right"
							icon="angle-right"
							class="w-100 h-100"
						></svg-icon>
					</button>
					<div class="app-l-ch__date">
						<mat-form-field>
							<input
								matInput
								[matDatepicker]="picker"
								[(ngModel)]="selectedDate"
								(dateChange)="onDateSelected($event)"
								disabled
								style="display: none"
							/>
							<mat-datepicker-toggle
								matSuffix
								[for]="picker"
								color="daily"
								disableRipple
							>
								<mat-icon matDatepickerToggleIcon> </mat-icon>
							</mat-datepicker-toggle>
							<mat-datepicker #picker disabled="false">
								<mat-datepicker-actions>
									<button aria-label="cancel"
										class="app-c-btn app-c-btn--secondary app-c-btn--sm app-c-btn--curve"
										matDatepickerCancel
										translate
									>
										calendar.cancel
									</button>
									<button aria-label="apply"
										class="app-c-btn app-c-btn--primary app-c-btn--sm app-c-btn--curve"
										mat-raised-button
										color="primary"
										(click)="setCalendarDate()"
										matDatepickerApply
										translate
									>
										calendar.apply
									</button>
								</mat-datepicker-actions>
							</mat-datepicker>
						</mat-form-field>
					</div>
				</div>

				<div
					class="app-l-calendar__details-toggle"
					*ngIf="!showSideView"
				>
					<button
						class="app-c-btn app-c-btn--secondary app-c-btn--icon-border app-c-btn--icon-32 app-l-btn-calendar__toggle"
						(click)="toggleSideView()"
					>
						<svg-icon icon="slide-left" alt="slide-left"></svg-icon>
					</button>
				</div>
			</div>
			<div class="app-l-calendar__body">
				<div class="app-l-view__calendar">
					<mwl-calendar-day-view
						[viewDate]="viewDate"
						[events]="dataNoFilter"
						[refresh]="refresh"
						[tooltipTemplate]=""
						(mouseOver)="disableTooltip($event)"
						[eventTemplate]="templatePlan"
					>
					</mwl-calendar-day-view>
				</div>
			</div>
			<ng-template #templatePlan let-weekEvent="weekEvent">
				<div
					class="app-l-view__day-b"
					[ngClass]="{
						'app-l-day__appointment':
							weekEvent.event.category === 'appointment',
						'app-l-day__activityscheduler':
							weekEvent.event.category === 'activityscheduler',
						'app-l-day__courses':
							weekEvent.event.category === 'courses',
						'app-l-day__homework':
							weekEvent.event.category !== 'appointment' &&
							weekEvent.event.category !== 'activityscheduler' &&
							weekEvent.event.category !== 'courses'
					}"
					(click)="toggleSideView()"
				>
					<!-- app-l-day__appointment, app-l-day__activityscheduler, app-l-day__homework -->
					<span
						class="app-l-view__day-name"
						*ngIf="weekEvent.event.category === 'appointment'"
						>{{ weekEvent.event.category | titlecase }}</span
					>
					<span
						class="app-l-view__day-name"
						*ngIf="weekEvent.event.category === 'courses'" translate
						>calendar.filter.courses</span
					>
					<span
						class="app-l-view__day-name"
						*ngIf="weekEvent.event.category === 'activityscheduler'" translate
						>calendar.filter.activityscheduler</span
					>
					<span
						class="app-l-view__day-name"
						*ngIf="
							weekEvent.event.category !== 'appointment' &&
							weekEvent.event.category !== 'activityscheduler' &&
							weekEvent.event.category !== 'courses'
						" translate
						>calendar.filter.homework</span
					>
					<span
						class="app-l-view__day-title"
						>{{ weekEvent.event.title | titleTruncate : weekEvent.event.duration }}</span
					>
					<span class="app-l-view__day-time">
						<span
							*ngIf="weekEvent.event.category === 'appointment'"
							translate
						>
							calendar.scheduled
						</span>
						<span *ngIf="weekEvent.event.category === 'appointment'"
							>:</span
						>
						<span *ngIf="weekEvent.event.category === 'appointment'"
							>{{ weekEvent.event.start | date : "shortTime" }}
						</span>
						<!-- <span
							*ngIf="
								weekEvent.event.category === 'activityscheduler'
							"
							>{{
								weekEvent.event.data.start | date : "shortTime"
							}}
						</span> -->
						<span
							*ngIf="weekEvent.event.category === 'appointment'"
							translate
						>
							calendar.to
						</span>
						<span
							*ngIf="weekEvent.event.category === 'appointment'"
							>{{
								weekEvent.event.end | date : "shortTime"
							}}</span
						>
						<!-- <span
							*ngIf="
								weekEvent.event.category === 'activityscheduler'
							"
							>{{
								weekEvent.event.data.end | date : "shortTime"
							}}</span
						> -->
					</span>
				</div>
			</ng-template>
		</div>

		<div *ngIf="showSideView" class="app-l-calendar__details">
			<div class="app-l-calendar__d-header">
				<div class="app-l-cl__dl">
					<h3 class="app-c-heading--h3">
						{{ startOfWeek | date : "MMMM dd" }} ,{{
							startOfWeek | date : "yyyy"
						}}
					</h3>
				</div>
				<div class="app-l-cl__dr">
					<button aria-label="toggleSideView"
						class="app-c-btn app-c-btn--secondary app-c-btn--icon-border app-c-btn--icon-32 app-l-btn-calendar__toggle"
						(click)="toggleSideView()"
					>
						<svg-icon icon="slide-right" alt="slide-right"></svg-icon>
					</button>
				</div>
			</div>

			<div class="app-l-calendar__d-body">
				<div class="app-l-calendar__d-opt scrollbar">
					<button aria-label="filterList"
						*ngFor="let item of filterList"
						class="app-c-btn app-c-btn--tertiary app-c-btn--sm"
						[ngClass]="{
							active: selectedFilter == item
						}"
						(click)="onSelectFilter(item)"
						translate
					>
						{{ "calendar.filter." + item }}
					</button>
				</div>
				<div class="app-l-cl__d-over">
					<div
						class="app-l-cl__d-item"
						*ngFor="
							let event of currentDateDataSideView;
							let index = index
						"
						[ngSwitch]="event.category"
					>
						<div
							class="app-l-cl__d-base"
							*ngSwitchCase="'appointment'"
						>
							<div class="app-l-cl__d-left">
								<p class="sub-title" translate>
									calendar.virtualCounseling
								</p>
								<h5 class="main-title">{{ event.title }}</h5>
								<div class="scheduled-status">
									<div class="app-l-ss__icon">
										<svg-icon alt="clock"
											icon="clock"
											class="w-100 h-100"
										></svg-icon>
									</div>
									<div class="app-l-ss__cnt">
										<span class="status-time" translate
											>calendar.scheduled</span
										>
										<span>:</span>
										<span>{{
											event.start | date : "shortTime"
										}}</span>
										<span translate> calendar.to </span>
										<span>{{
											event.end | date : "shortTime"
										}}</span>
									</div>
								</div>
							</div>
							<div class="app-l-cl__d-right">
								<button aria-label="view more button"
									class="app-c-btn app-c-btn--secondary app-c-btn--icon-border app-c-btn--icon-20"
								>
									<svg-icon alt="angle-right"
										icon="angle-right"
										class="w-100 h-100"
									></svg-icon>
								</button>
							</div>
						</div>
						<div
							class="app-l-cl__d-base"
							*ngSwitchCase="'activityscheduler'"
							(click)="
								onEventSelect('activityscheduler', event, index)
							"
						>
							<div class="app-l-cl__d-left">
								<p class="sub-title" translate>
									calendar.activity
								</p>
								<h5 class="main-title">
									{{ event.data?.title }}
								</h5>

								<div class="scheduled-status">
									<div class="app-l-ss__icon">
										<svg-icon alt="clock"
											icon="clock"
											class="w-100 h-100"
										></svg-icon>
									</div>
									<div class="app-l-ss__cnt">
										<span class="status-time" translate
											>calendar.scheduled</span
										>
										<span> :</span>
										<span class="app-l-ss__t-p"
											>{{
												event.data.start
													| date : "shortTime"
											}}
										</span>
										<span translate>calendar.to</span>
										<span class="app-l-ss__t-p">{{
											event.data.end | date : "shortTime"
										}}</span>
									</div>
								</div>
								<div class="completed-status">
									<div
										class="app-l-cl__complete"
										*ngIf="event.data?.status == 'complete'"
									>
										<svg-icon icon="tick-circle" alt="tick-circle"></svg-icon>
										<span translate
											>calendar.completed</span
										>
									</div>
									<button aria-label="edit button"
										*ngIf="
											!event.data?.status == 'complete' ||
											event.data?.status == 'inprogress'
										"
										class="app-c-btn app-c-btn--curve app-c-btn--secondary app-c-btn--sm"
										(click)="
											openEditActivityModal(
												'activityscheduler',
												event,
												index
											)
										"
										translate
									>
										calendar.edit
									</button>
									<button aria-label="inprogress"
										*ngIf="
											event.data?.status == 'inprogress'
										"
										class="app-c-btn app-c-btn--curve app-c-btn--secondary app-c-btn--sm"
										(click)="
											setMarkAsCompleted(
												'activityscheduler',
												event,
												index
											)
										"
										translate
									>
										calendar.markAsComplete
									</button>
								</div>
							</div>
							<div class="app-l-cl__d-right">
								<button aria-label="Edit Activity"
									*ngIf="event.data?.status != 'complete'"
									class="app-c-btn app-c-btn--secondary app-c-btn--icon-border app-c-btn--icon-20"
									(click)="
										openEditActivityModal(
											'activityscheduler',
											event,
											index
										)
									"
								>
									<svg-icon alt="angle-right"
										icon="angle-right"
										class="w-100 h-100"
									></svg-icon>
								</button>
							</div>
						</div>
						<div
							class="app-l-cl__d-base"
							*ngSwitchCase="'courses'"
							(click)="onEventSelect('courses', event, index)"
						>
							<div class="app-l-cl__d-left">
								<p class="sub-title" translate>
									calendar.recommendedCourseByCounselor
								</p>
								<h5 class="main-title">{{ event.title }}</h5>
								<div class="scheduled-status">
									<div class="app-l-ss__icon">
										<svg-icon
											icon="clock" alt="clock"
											class="w-100 h-100"
										></svg-icon>
									</div>
									<div class="app-l-ss__cnt">
										<span class="status-time" translate>
											calendar.scheduled
										</span>
										<span>:</span>
										<span class="app-l-ss__t-p">{{
											event.start | date : "MMM dd yyyy"
										}}</span>
									</div>
								</div>
							</div>
							<div class="app-l-cl__d-right">
								<button aria-label="view-more-button"
									[routerLink]="[
										'/app/series-details/' +
											(event.course_key | lowercase)
									]"
									class="app-c-btn app-c-btn--secondary app-c-btn--icon-border app-c-btn--icon-20"
								>
									<svg-icon alt="angle-right"
										icon="angle-right"
										class="w-100 h-100"
									></svg-icon>
								</button>
							</div>
						</div>
						<div
							class="app-l-cl__d-base"
							*ngSwitchDefault
							(click)="
								onEventSelect(event.category, event, index)
							"
						>
							<div class="app-l-cl__d-left">
								<p class="sub-title" translate>
									calendar.homework
								</p>
								<h5 class="main-title">
									{{ event.title || event.typeName }}
								</h5>
								<div class="scheduled-status">
									<div class="app-l-ss__icon">
										<svg-icon alt="clock"
											icon="clock"
											class="w-100 h-100"
										></svg-icon>
									</div>
									<div class="app-l-ss__cnt">
										<span class="status-time" translate>
											calendar.scheduled
										</span>
										<span> :</span>
										<span class="app-l-ss__t-p" translate>{{
											event.start | date : "MMM dd yyyy"
										}}</span>
									</div>
								</div>
							</div>
							<div class="app-l-cl__d-right">
								<button aria-label="view-more-button" *ngIf="event.status && event.status != 'completed'"
									class="app-c-btn app-c-btn--secondary app-c-btn--icon-border app-c-btn--icon-20"
								>
									<svg-icon alt="angle-right"
										icon="angle-right"
										class="w-100 h-100"
									></svg-icon>
								</button>
							</div>
						</div>
					</div>
					<div
						*ngIf="!currentDateDataSideView.length"
						class="app-l-no-mood-check"
					>
						<div class="no-data-image">
							<svg-icon icon="room-for-positive" alt="room-for-positive"></svg-icon>
							<span
								class="app-l-no-mood__round app-l-no-mood__round1"
							></span>
							<span
								class="app-l-no-mood__round app-l-no-mood__round2"
							></span>
							<span
								class="app-l-no-mood__round app-l-no-mood__round3"
							></span>
						</div>
						<h5 class="app-c-body-text--16-m" translate>
							calendar.noDataFound
						</h5>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
