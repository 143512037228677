import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    ElementRef,
    ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { Zenroom } from 'app/models/zenroom.modal';
import { OwlOptions } from 'ngx-owl-carousel-o';
// import { AnalyticEvent } from 'app/lib/analytic-event';
import { ApiService } from '../../../../app/lib/api.service';
import { LogService } from '../../../../app/lib/log.service';
import { UrlService } from 'app/lib/url.service';
import { CarouselComponent } from 'ngx-owl-carousel-o';
import { AnalyticEvent } from 'app/lib/analytic-event';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-zen-room',
    templateUrl: './zen-room.component.html',
    styleUrls: ['./zen-room.component.scss'],
})
export class ZenRoomPage implements OnInit, OnDestroy {
    @ViewChild('meditationaudioplayer') videoPlayer: ElementRef;
    @ViewChild('subtitle') subtitle: ElementRef;
    @ViewChild('owlCarouselAmbiance') owlCarouselAmbiance: CarouselComponent;
    @ViewChild('owlCarouselMeditation')
    owlCarouselMeditation: CarouselComponent;
    customOptions: OwlOptions = {
        loop: false,
        navSpeed: 200,
        dots: false,
        freeDrag: false,
        pullDrag: false,
        mouseDrag: true,
        navText: [
            "<i tabindex='0' role='button' class='fa fa-angle-left'></i>",
            "<i tabindex='0' role='button' class='fa fa-angle-right'></i>",
        ],
        autoWidth: true,
        margin: 0,
        items: 7.5,
        slideBy: 3,
        responsive: {
            0: {
                items: 1,
                slideBy: 1,
            },
            200: {
                items: 1.5,
                slideBy: 1,
            },
            400: {
                items: 2.5,
                slideBy: 1,
            },
            600: {
                items: 3,
                slideBy: 2,
            },
            800: {
                items: 4,
                slideBy: 3,
            },
            900: {
                items: 4.5,
                slideBy: 3,
            },
            1000: {
                items: 5,
                slideBy: 3,
            },
            1100: {
                items: 5.5,
                slideBy: 4,
            },
            1200: {
                items: 6.5,
                slideBy: 5,
            },
            1300: {
                items: 7,
                slideBy: 5,
            },
            1400: {
                items: 7.5,
                slideBy: 5,
            },
            1500: {
                items: 8,
                slideBy: 5,
            },
        },
        nav: true,
        lazyLoad: true,
    };
    playing = false;
    backgroundVolume = 20;
    voiceVolume = 50;
    backgroundVolumeRounded = 5;
    voiceVolumeRounded = 5;
    backgroundTrack: HTMLAudioElement;
    voiceTrack: HTMLAudioElement;
    ambianceType: string = 'none';
    voiceType: string = 'none';
    mute: boolean = false;
    duration: number; //play time in seconds
    playTime: string; //user facing time format of the duration
    timer: any;
    backLink: string;
    title: string;
    midTitle: string;
    back: string;
    playText: string;
    pauseText: string;
    language: string;

    vttUrl: string = '';
    vgApi: VgApiService;
    voiceTrackEnded = true;
    none: Zenroom = {
        key: 'none',
        title: 'None',
        originalTitle: '',
        imageUrl: './assets/img/zenroom/none.png',
        audioUrl: '',
        vttUrl: '',
        courseID: null,
    };

    infoText: string = 'This is opened.';
    helpOpen: boolean = false;
    meditationTrackList = [];
    ambianceTrackList = [];
    audioEndpoints;
    any;
    selectedAmbiance: any;
    selectedMeditation: any;
    isLoaded = false;
    expandPlayer: boolean = true;
    isAudioSelected: boolean;
    meditationName: any;
    ambianceName: any;
    showSubtitle: boolean = false;
    previousUrl: any;
    type: any;
    quizId: any;
    constructor(
        private translate: TranslateService,
        private api: ApiService,
        private router: Router,
        private log: LogService,
        private urlService: UrlService,
        private activatedRoute: ActivatedRoute
    ) {
        if (
            this.urlService.getPreviousUrl() === '/app/assessment/das/listing'
        ) {
            this.backLink = this.urlService.getPreviousUrl();
        } else {
            this.backLink = '/app/practice';
        }
        this.activatedRoute.queryParams.subscribe(params => {
            this.previousUrl = params['prevpage'];
            this.type = params['type'];
            this.quizId = params['id'];
        });
    }

    ngOnInit() {
        this.log.event(AnalyticEvent.event.zenroomLoaded);
        this.language = this.translate.currentLang;
        this.getZenroomTrackList();
        this.translate.stream('zenRoom').subscribe((res: any) => {
            this.title = res.title;
            this.midTitle = res.subtitle;
            this.back = res.back;
            this.playText = res.play;
            this.pauseText = res.pause;
        });
    }
    getZenroomTrackList() {
        this.api.get('series/zenroomv2').subscribe(
            (result: any) => {
                this.audioEndpoints = result;
                this.isLoaded = true;
                this.initTrackList();
            },
            (error: any) => {
                this.log.error('Error getting Audio Tracks. ' + error.message);
            },
            () => {}
        );
    }
    initTrackList() {
        if (this.audioEndpoints.background.length) {
            this.backgroundTrack = <HTMLAudioElement>(
                document.getElementById('ambiance-audio-player')
            );
            let item: Zenroom = { ...this.none };
            item.imageUrl = './assets/img/zenroom/none.png';
            this.audioEndpoints.background.unshift(item);
            this.audioEndpoints.background.map(item => {
                item['isSelected'] = false;
                item['isCourseCompleted'] = false;
            });
            this.ambianceTrackList = [...this.audioEndpoints.background];
            this.onChooseBackgroundTrack(this.none);
        }
        if (this.audioEndpoints.voice.length) {
            this.voiceTrack = <HTMLAudioElement>(
                document.getElementById('meditation-audio-player')
            );
            let item: Zenroom = { ...this.none };
            item.imageUrl = './assets/img/zenroom/none.png';
            this.audioEndpoints.voice.unshift(item);
            this.audioEndpoints.voice.map(item => {
                item['isSelected'] = false;
                item['isCourseCompleted'] = false;
            });
            this.meditationTrackList = [...this.audioEndpoints.voice];
            this.onChooseVoiceTrack(this.none);
        }
        this.duration = 0;
    }
    ngOnDestroy() {
        this.stop();

        if (this.backgroundTrack) {
            this.backgroundTrack.remove();
            this.backgroundTrack = null;
        }

        if (this.voiceTrack) {
            this.voiceTrack.remove();
            this.voiceTrack = null;
        }
    }

    checkAudioSelected() {
        if (this.ambianceType === 'none' && this.voiceType === 'none') {
            this.playing = false;
            this.isAudioSelected = false;
        } else if (this.ambianceType === 'none' && this.voiceTrackEnded) {
            this.playing = false;
        } else {
            this.isAudioSelected = true;
        }
    }

    onChooseBackgroundTrack(ambiance: any, event?: Event) {
        event?.stopPropagation();
        if (event?.defaultPrevented) {
            return;
        }
        event?.preventDefault();
        this.ambianceName = ambiance.title;
        const sourceTrack = document.getElementById('ambiance-source');
        this.selectedAmbiance = { ...ambiance };
        this.ambianceType = ambiance.key;
        this.ambianceTrackList.map(item => {
            item['isSelected'] = item.key === ambiance.key ? true : false;
        });
        this.checkAudioSelected();
        if (!ambiance.audioUrl) {
            if (this.backgroundTrack) {
                this.backgroundTrack.pause();
                this.backgroundTrack = null;
            }
            return;
        }
        if (!this.backgroundTrack) {
            this.backgroundTrack = <HTMLAudioElement>(
                document.getElementById('ambiance-audio-player')
            );
        }
        sourceTrack.setAttribute('src', ambiance.audioUrl);
        this.backgroundTrack.src = ambiance.audioUrl;
        this.backgroundTrack.loop = true;
        this.backgroundTrack.volume = this.backgroundVolume / 100;
        this.backgroundTrack.load();

        if (this.playing) {
            this.backgroundTrack.play();
            this.log.event(AnalyticEvent.event.zenroomPlayBackgroundTrack);
            this.ambianceTracking();
        }
    }

    onChooseVoiceTrack(meditation: any, event?: Event) {
        event?.stopPropagation();
        if (event?.defaultPrevented) {
            return;
        }
        event?.preventDefault();
        this.meditationName = meditation.title;
        const sourceTrack = document.getElementById('audio-source');
        this.selectedMeditation = { ...meditation };
        this.voiceType = meditation.key;
        this.meditationTrackList.map(item => {
            item['isSelected'] = item.key === meditation.key ? true : false;
        });
        this.subtitle.nativeElement.innerHTML = '';
        this.showSubtitle = false;
        this.voiceTrackEnded = false;
        this.checkAudioSelected();
        if (!meditation.audioUrl) {
            if (this.voiceTrack) {
                this.voiceTrack.pause();
                this.voiceTrack.currentTime = 0;
                this.voiceTrack = null;
            }
            this.vttUrl = '';
            return;
        }

        if (!this.voiceTrack) {
            this.voiceTrack = <HTMLAudioElement>(
                document.getElementById('meditation-audio-player')
            );
        }
        sourceTrack.setAttribute('src', meditation.audioUrl);
        this.vttUrl = meditation.vttUrl;
        this.vttTrackListener();
        this.voiceTrack.volume = this.voiceVolume / 100;
        this.voiceTrack.load();
        if (this.playing) {
            this.voiceTrack.play();
            this.log.event(AnalyticEvent.event.zenroomPlayVoiceTrack);
            this.voiceTracking();
        }
    }
    vttTrackListener() {
        this.subtitle.nativeElement.innerHTML = '';
        this.showSubtitle = false;
        const video = this.videoPlayer.nativeElement;
        const track = video.textTracks[0];
        track.mode = 'hidden';
        track.addEventListener('cuechange', () => {
            const cue = track.activeCues[0];
            if (cue) {
                this.subtitle.nativeElement.innerHTML = cue.text;
                this.showSubtitle = true;
            } else {
                this.subtitle.nativeElement.innerHTML = '';
                this.showSubtitle = false;
            }
        });
    }
    onValueChangeBackground(value) {
        value = parseInt(value);
        if (value) {
            if (value > 10) {
                value = 10;
            }
            if (value < 0) {
                value = 0;
            }
            this.backgroundVolume = value * 10;
            this.backgroundVolumeRounded = value;
        }
    }

    onValueChangeVoice(value) {
        value = parseInt(value);
        if (value) {
            if (value > 10) {
                value = 10;
            }
            if (value < 0) {
                value = 0;
            }
            this.voiceVolume = value * 10;
            this.voiceVolumeRounded = value;
        }
    }

    play() {
        this.timer = setInterval(() => {
            this.tick();
        }, 1000);

        if (this.backgroundTrack) {
            this.backgroundTrack.play();
        }

        if (this.voiceTrack) {
            this.voiceTrackEnded = false;
            this.voiceTrack.play();
        }
        this.log.event(AnalyticEvent.event.zenroomPlay);
    }

    pause() {
        clearInterval(this.timer);

        if (this.backgroundTrack) {
            this.backgroundTrack.pause();
        }

        if (this.voiceTrack) {
            this.voiceTrack.pause();
        }
    }

    stop() {
        this.pause();
        this.duration = 0;
        this.setPlayTime();

        if (this.backgroundTrack) {
            this.backgroundTrack.currentTime = 0;
        }

        if (this.voiceTrack) {
            this.voiceTrack.currentTime = 0;
        }
    }

    onToggle() {
        if (this.mute) {
            this.mute = false;
            this.voiceTrack.muted = false;
            this.backgroundTrack.muted = false;
        } else {
            this.mute = true;
            this.voiceTrack.muted = true;
            this.backgroundTrack.muted = true;
        }
    }

    tick() {
        this.duration++;
        this.setPlayTime();
    }

    setPlayTime() {
        let date = new Date(null);
        date.setSeconds(this.duration);
        this.playTime = date.toISOString().substr(11, 8);
    }

    onPlay() {
        if (this.playing) {
            this.pause();
        } else {
            this.play();
            this.ambianceTracking();
            this.voiceTracking();
        }
        this.playing = !this.playing;
    }

    onStop() {
        this.playing = false;
        this.stop();
    }
    voiceVolumeChange(value: any) {
        this.voiceVolume = value;
        if (this.voiceTrack) {
            let newVol = 0.0;
            newVol = value / 100;
            this.voiceTrack.volume = newVol;
            this.videoPlayer.nativeElement.volume = newVol;
        }
    }

    backgroundVolumeChange(value: any) {
        this.backgroundVolume = value;
        if (this.backgroundTrack) {
            let newVol = 0.0;
            newVol = value / 100;
            this.backgroundTrack.volume = newVol;
        }
    }

    ambianceTracking() {
        this.ambianceType != 'none' && this.onTimeUpdate(this.selectedAmbiance);
    }

    voiceTracking() {
        this.voiceType != 'none' && this.onTimeUpdate(this.selectedMeditation);
    }

    onTimeUpdate(dataItem: any) {
        if (dataItem && dataItem.courseID && !dataItem.isCourseCompleted) {
            this.api
                .post('series/complete', { id: dataItem.courseID })
                .subscribe(
                    (result: any) => {
                        this.log.debug('Course progress saved successfully.');
                        dataItem.isCourseCompleted = true;
                    },
                    (error: any) => {
                        this.log.error('Error issuing callback. ');
                    }
                );
        }
    }

    onToggleText() {
        if (this.voiceType) {
            this.helpOpen = !this.helpOpen;
        }
    }

    searchChange(event, type) {
        const query = event.target.value.toLowerCase();
        if (type === 'ambiance') {
            this.ambianceTrackList = this.audioEndpoints.background.filter(
                d => d.title.toLowerCase().indexOf(query) > -1
            );
        } else {
            this.meditationTrackList = this.audioEndpoints.voice.filter(
                d => d.title.toLowerCase().indexOf(query) > -1
            );
        }
    }
    expand() {
        this.expandPlayer = this.expandPlayer ? false : true;
    }

    onPlayerReady(api: VgApiService) {
        this.vgApi = api;
        this.vgApi.getDefaultMedia().subscriptions.ended.subscribe(() => {
            this.voiceTrackEnded = true;
            if (
                this.backgroundTrack === null ||
                this.backgroundTrack === undefined
            ) {
                this.playing = false;
            }
        });
    }

    onPrevClick(type) {
        if (type === 'ambiance') {
            this.owlCarouselAmbiance.prev();
        } else {
            this.owlCarouselMeditation.prev();
        }
    }

    onNextClick(type) {
        if (type === 'ambiance') {
            this.owlCarouselAmbiance.next();
        } else {
            this.owlCarouselMeditation.next();
        }
    }

    goBack() {
        if (this.previousUrl === 'result') {
            this.router.navigate([
                '/app/assessment/result/' + this.quizId + '/' + this.type,
            ]);
        } else {
            this.router.navigate(['/app/practice']);
        }
    }
}
