<div class="app-l-mc__sidepanel-wrap" [ngClass]="cssClass">
    <div class="app-l-mc__head">
        <div class="userevent-head">
            <label translate>eventAssign.selectOptionToAssign</label>
            <button
                class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-20"
                (click)="cancel()"
                (keypress)="cancel()">
                <svg-icon
                    icon="modal-close-button"
                    class="w-100 h-100"></svg-icon>
            </button>
        </div>
    </div>
    <div class="app-l-mc__body">
        <label class="required" translate>eventAssign.assignee</label>
        <mat-form-field>
            <mat-select
                class="wt-auto-login-organization"
                name="orgId"
                [placeholder]="
                    !selectedUser && 'eventAssign.chooseFromList' | translate
                "
                [(ngModel)]="selectedUser"
                [disabled]="disableUserList">
                <ng-container *ngFor="let user of userList">
                    <mat-option *ngIf="user.Synched" [value]="user">
                        {{ user.fullName || user.Name }}
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <label class="required" translate>eventAssign.selectEventType</label>
        <mat-form-field>
            <mat-select
                class="wt-auto-login-organization"
                name="eventType"
                [(ngModel)]="selectedEventType"
                [disabled]="disableEventList"
                [placeholder]="
                    !selectedEventType && 'eventAssign.chooseFromList'
                        | translate
                "
                (ngModelChange)="onChangeEventType()">
                <mat-option *ngFor="let event of eventTypeList" [value]="event">
                    {{ event.name | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <label
            class="required"
            *ngIf="selectedEventType?.subOptions?.length"
            translate
            >eventAssign.selectSpecificTask</label
        >
        <mat-form-field
            *ngIf="
                selectedEventType?.subOptions?.length &&
                !selectedEventType.multiSelect
            ">
            <mat-select
                class="wt-auto-login-organization"
                name="event-single-select"
                [placeholder]="
                    !selectedEvent && 'eventAssign.chooseFromList' | translate
                "
                [(ngModel)]="selectedEvent">
                <ng-container
                    *ngFor="let option of selectedEventType?.subOptions">
                    <mat-option
                        *ngIf="option.enabled"
                        [value]="option"
                        translate>
                        <span [translate]="option.name"></span>
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>

    <!-- <label class="required" translate>eventAssign.selectEventType</label> -->
        <mat-form-field
            *ngIf="
                selectedEventType?.subOptions?.length &&
                selectedEventType.multiSelect
            ">
            <mat-select
                class="wt-auto-login-organization"
                name="event-multi-select"
                [placeholder]="
                    !selectedEvent && 'eventAssign.chooseFromList' | translate
                "
                [(ngModel)]="selectedEvent"
                [multiple]="true">
                <ng-container
                    *ngFor="let option of selectedEventType?.subOptions">
                    <mat-option
                        *ngIf="option.enabled"
                        [value]="option"
                        translate>
                        <span [translate]="option.name"></span>
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>


        <label class="required" translate
            >eventAssign.SuggestedCompletionDate</label
        >
        <div class="app-c-datepicker app-c-form-group">
            <input
                class="app-c-form-control"
                [owlDateTime]="dt1"
                [owlDateTimeTrigger]="dt1"
                placeholder="Select Date"
                [(ngModel)]="selectedDate"
                [min]="minDate" />
            <div class="app-c-datepicker__icon">
                <svg-icon icon="calendar"></svg-icon>
            </div>
            <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
        </div>
    </div>
    <div class="footer-popup">
        <button
            tabindex="0"
            class="app-c-btn app-c-btn--secondary"
            (click)="cancel()"
            (keypress)="cancel()">
            <!-- *ngIf="!optionChanged" -->
            <span translate>blocked.close</span>
        </button>
        <button
            class="app-c-btn app-c-btn--primary"
            tabindex="0"
            [disabled]="
                !selectedUser ||
                !selectedEventType ||
                (selectedEventType?.subOptions && !selectedEvent) ||
                this.isLoading
            "
            (click)="onSubmit()"
            (keypress)="onSubmit()">
            <span translate>eventAssign.assign</span>
            <!-- <svg-icon icon="tick"></svg-icon> -->
            <mat-spinner *ngIf="isLoading"></mat-spinner>
        </button>
    </div>
</div>
<span class="app-l-mc__overlay" (click)="cancel()" (keypress)="cancel()"></span>
