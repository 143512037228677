export class ASSESSMENT_TYPES_CONFIG {
    public static ASSESSMENT_LIST_LANG = {
        mentalwellbeing: {
          title: 'assessmentTypeListing.types.mentalWellBeingAssessment.title',
          description:
              'assessmentTypeListing.types.mentalWellBeingAssessment.description',
        },
        das: {
            title: 'assessmentTypeListing.types.dasAssessment.title',
            description:
                'assessmentTypeListing.types.dasAssessment.description',
        },
        dass: {
            title: 'assessmentTypeListing.types.dasAssessment.title',
            description:
                'assessmentTypeListing.types.dasAssessment.description',
        },
        resilience: {
            title: 'assessmentTypeListing.types.resAssessment.title',
            description:
                'assessmentTypeListing.types.resAssessment.description',
        },
        alcoholuse: {
            title: 'assessmentTypeListing.types.auAssessment.title',
            description: 'assessmentTypeListing.types.auAssessment.description',
        },
        anxiety: {
            title: 'assessmentTypeListing.types.anxietyAssessment.title',
            description:
                'assessmentTypeListing.types.anxietyAssessment.description',
        },
        depression: {
            title: 'assessmentTypeListing.types.depressionAssessment.title',
            description:
                'assessmentTypeListing.types.depressionAssessment.description',
        }
    };
}
export class TOOLS_CONFIG {
    public static TOOLS_LIST_LANG = {
        zenroom: {
            title: 'zenRoom.title',
            description: 'practice.zen.body',
            imageUrl: '',
            alt: 'zen-room',
            toolGroup: '',
            route: '/app/practice/zen-room',
        },
        thoughtdiary: {
            title: 'practice.thoughtDiary.title',
            description: 'practice.thoughtDiary.body',
            imageUrl: './assets/img/Rectangle 4350.png',
            alt: 'Thoughtdiary',
            toolGroup: 'thoughts',
            route: '/app/practice/thoughtdiary',
        },
        funachievement: {
            title: 'practice.fa.title',
            description: 'practice.fa.body',
            imageUrl: './assets/img/Rectangle 4350 (1).png',
            alt: 'Fun Achievement',
            toolGroup: 'actions',
            route: '/app/practice/funachievement',
        },
        quiz: {
            title: 'practice.cdQuiz.title',
            description: 'practice.cdQuiz.body',
            imageUrl: './assets/img/Rectangle 4350 (2).png',
            alt: 'Thought Distortions Quiz',
            toolGroup: 'thoughts',
            route: '/app/practice/cd-quiz',
        },
        activityscheduler: {
            title: 'practice.as.title',
            description: 'practice.as.body',
            imageUrl: './assets/img/Rectangle 4350 (3).png',
            alt: 'Activity Scheduler',
            toolGroup: 'actions',
            route: '/app/practice/activityscheduler',
        },
        roomForPossitive: {
            title: 'practice.rfp.title',
            description: 'practice.rfp.body',
            imageUrl: './assets/img/icons-svg/room_for_positive.svg',
            alt: 'Room-For-Positive',
            toolGroup: 'thoughts',
            route: '/app/practice/room-for-positive/listing',
        },
        actionPlan: {
            title: 'actionPlan.actionPlan',
            description: 'practice.actionPlan.body',
            imageUrl: './assets/img/My-Action-Plan 3.svg',
            alt: 'My Action Plan',
            toolGroup: 'actions',
            route: '/app/practice/action-plan',
        },
        actionplan: {
            title: 'actionPlan.actionPlan',
            description: 'practice.actionPlan.body',
            imageUrl: './assets/img/My-Action-Plan 3.svg',
            alt: 'My Action Plan',
            toolGroup: 'actions',
            route: '/app/practice/action-plan',
        },
    };
}
export class ADMIN_CONFIG {
    public static USER_ASSIGN_ASSESSMENT_TYPES = {
        das: 'assessment',
        dass: 'assessment',
        resilience: 'BRCS',
        alcoholuse: 'AUDIT',
        anxiety: 'GAD-7',
        depression: 'PHQ-9',
        mentalwellbeing: 'mentalwellbeing'
    };
    public static USER_ASSIGN_TYPES_LIST = [
        {
            name: 'menu.moodcheck',
            type: 'moodcheck',
            multiSelect: false,
            subOptions: null,
        },
        {
            name: 'menu.assessment',
            type: 'assessment',
            multiSelect: false,
            subOptions: [],
        },
        {
            name: 'menu.theory',
            type: 'series',
            multiSelect: true,
            subOptions: [],
        },
        {
            name: 'menu.tools',
            type: 'tools',
            multiSelect: false,
            subOptions: [],
        },
        // {
        //     name: 'actionPlan.actionPlan',
        //     type: 'actionplan',
        //     multiSelect: false,
        //     subOptions: null,
        // },
    ];
}

export class MW_ASSESSMENT_CONFIG {
    public static INTRO_QUES_RR = {
        1: {
            value: [
                {
                    type: 'series',
                    name: 'dynamicAssessment.mentalwellbeing.recommendations.disaster',
                    key: 'disaster',
                    sortOrder: 1,
                    image: '',
                },
            ],
        },
        2: {
            value: [
                {
                    type: 'series',
                    name: 'dynamicAssessment.mentalwellbeing.recommendations.healthyconnections',
                    key: 'healthyconnections',
                    sortOrder: 3,
                    image: '',
                },
            ],
        },
        3: {
            value: [
                {
                    type: 'series',
                    name: 'dynamicAssessment.mentalwellbeing.recommendations.anxiety',
                    key: 'anxiety',
                    sortOrder: 3,
                    image: '',
                },
                {
                    type: 'tool',
                    name: 'practice.thoughtDiary.title',
                    key: 'thought-diary',
                    path: '/app/practice/thoughtdiary',
                    sortOrder: 3,
                    image: '../../../../assets/img/Rectangle 4350.png',
                },
            ],
        },
        4: {
            value: [
                {
                    type: 'series',
                    name: 'dynamicAssessment.mentalwellbeing.recommendations.emotions',
                    key: 'emotions',
                    sortOrder: 3,
                    image: '',
                },
            ],
        },
        5: {
            value: [
                {
                    type: 'series',
                    name: 'dynamicAssessment.mentalwellbeing.recommendations.depression_new',
                    key: 'depression_new',
                    sortOrder: 3,
                    image: '',
                },
                {
                    type: 'moodcheck',
                    name: 'moodcheck.title',
                    key: 'moodcheck',
                    path: '/app/mood-check',
                    sortOrder: 3,
                    image: '',
                },
            ],
        },
        6: {
            value: [
                {
                    type: 'series',
                    name: 'dynamicAssessment.mentalwellbeing.recommendations.mindfulness',
                    key: 'mindfulness',
                    sortOrder: 3,
                    image: '',
                },
                {
                    type: 'tool',
                    name: 'practice.zen.title',
                    key: 'zen-room',
                    path: '/app/practice/zen-room',
                    sortOrder: 3,
                    image: '../../../../assets/img/zenroom/zen-room-bg.svg',
                },
            ],
        },
        7: {
            value: [
                {
                    type: 'series',
                    name: 'dynamicAssessment.mentalwellbeing.recommendations.sleep',
                    key: 'sleep',
                    sortOrder: 3,
                    image: '',
                },
            ],
        },
        8: {
            value: [
                {
                    type: 'series',
                    name: 'dynamicAssessment.mentalwellbeing.recommendations.purpose',
                    key: 'purpose',
                    sortOrder: 3,
                    image: '',
                },
            ],
        },
        9: {
            value: [
                {
                    type: 'tool',
                    name: 'practice.rfp.title',
                    key: 'room-for-positive',
                    path: '/app/practice/room-for-positive/listing',
                    sortOrder: 3,
                    image: '../../../../assets/img/icons-svg/room_for_positive.svg',
                },
            ],
        },
        10: {
            value: [
                {
                    type: 'getHelpNow',
                    name: 'contact.getHelpNow',
                    key: 'getHelpNow',
                    sortOrder: 0,
                    image: '',
                },
            ],
        },
        11: {
            value: [
                {
                    type: 'welltrackConnect',
                    name: 'Welltrack Connect',
                    key: 'welltrackConnect',
                    path: 'https://welltrack-connect.com/',
                    sortOrder: 3,
                    image: '',
                },
            ],
        },
        12: {
            value: [
                {
                    type: 'page',
                    name: 'pagetitles.resources',
                    key: 'resources',
                    path: '/app/resources',
                    sortOrder: 2,
                    image: '',
                },
                {
                    type: 'page',
                    name: 'pagetitles.assessment',
                    key: 'assessment',
                    path: '/app/assessments/listing',
                    sortOrder: 3,
                    image: '',
                },
            ],
        },
    };
    public static ASSESSMENT_QUES_RR = [
        {
            answerValues: ['1', '2'],
            value: [
                {
                    type: 'series',
                    name: 'dynamicAssessment.mentalwellbeing.recommendations.depression_new',
                    key: 'depression_new',
                    sortOrder: 3,
                    image: '',
                },
                {
                    type: 'series',
                    name: 'dynamicAssessment.mentalwellbeing.recommendations.disaster',
                    key: 'disaster',
                    sortOrder: 1,
                    image: '',
                },
            ],
        },
        {
            answerValues: ['1', '2', '3'],
            value: [
                {
                    type: 'series',
                    name: 'dynamicAssessment.mentalwellbeing.recommendations.purpose',
                    key: 'purpose',
                    sortOrder: 3,
                    image: '',
                },
            ],
        },
        {
            answerValues: ['1', '2', '3'],
            value: [
                {
                    type: 'series',
                    name: 'dynamicAssessment.mentalwellbeing.recommendations.mindfulness',
                    key: 'mindfulness',
                    sortOrder: 3,
                    image: '',
                },
                {
                    type: 'tool',
                    name: 'practice.zen.title',
                    key: 'zen-room',
                    path: '/app/practice/zen-room',
                    sortOrder: 3,
                    image: '../../../../assets/img/zenroom/zen-room-bg.svg',
                },
            ],
        },
        {
            answerValues: ['1', '2', '3'],
            value: [
                {
                    type: 'series',
                    name: 'dynamicAssessment.mentalwellbeing.recommendations.disaster',
                    key: 'disaster',
                    sortOrder: 1,
                    image: '',
                },
            ],
        },
        {
            answerValues: ['1', '2'],
            value: [
                {
                    type: 'series',
                    name: 'dynamicAssessment.mentalwellbeing.recommendations.anxiety',
                    key: 'anxiety',
                    sortOrder: 3,
                    image: '',
                },
                {
                    type: 'tool',
                    name: 'practice.thoughtDiary.title',
                    key: 'thought-diary',
                    path: '/app/practice/thoughtdiary',
                    sortOrder: 3,
                    image: '../../../../assets/img/Rectangle 4350.png',
                },
            ],
        },
        {
            answerValues: ['1', '2', '3'],
            value: [
                {
                    type: 'series',
                    name: 'dynamicAssessment.mentalwellbeing.recommendations.healthyconnections',
                    key: 'healthyconnections',
                    sortOrder: 3,
                    image: '',
                },
            ],
        },
        {
            answerValues: ['1', '2'],
            value: [
                {
                    type: 'series',
                    name: 'Interpersonal Violence',
                    key: 'interpersonalviolence',
                    sortOrder: 3,
                    image: '',
                },
            ],
        },
    ];
}

export class COMMON_CONFIG {
    public static POWER_BI_REPORT_TYPES = {
        clientReport: 'Welltrack Client Report',
        adminReport: 'Welltrack Admin Report',
        organizationReport: 'Welltrack Organization Report',
    };
}
