import { ThrowStmt } from "@angular/compiler";
import { TranslationServiceHelper } from "../lib/translation.service";

export class Course {
	id: number;
	key: string;
	label: string;
	category: number;
	courseTitle: string;
	description: string;
	isCompleted: boolean;
	created: Date;
	length: string;
	default: boolean;
	active: boolean;
	talkAboutDescription: string;
	talkAboutLink: string;
	chapters: Chapter[] = [];
	courseImage: fileUpload;
	videos: Video[] = [];
	courseName: string;

	public static initializeArray(objects: any): Course[] {
		let results: Course[] = [];
		for (let i = 0; i < objects.length; i++) {
			let obj = new Course(objects[i][0]);
			results.push(obj);
		}

		return results;
	}

	constructor(data: any) {
		if (data) {
			this.id = data.ID || data.id;
			this.courseTitle = data.CourseTitle || data.courseTitle;
			this.label = data.label || data.Label;
			this.category = data.Category || data.category;
			this.key = data.key || data.Key;
			this.length = data.length || data.Length;
			this.description = data.Description || data.description;
			this.default =
				data.Default ||
				data.default ||
				data.DefaultCourse ||
				data.defaultCourse;
			this.active = data.Active || data.active;
			this.isCompleted = data.IsCompleted || data.iscompleted || false;
			this.talkAboutDescription =
				data.TalkAboutDescription || data.talkAboutDescription;
			this.talkAboutLink = data.TalkAboutLink || data.talkAboutLink;
			this.created = data.createdOnUtc || data.CreatedOnUtc;
			this.courseImage = new fileUpload(
				data.CourseImage || data.courseImage
			);
			this.courseName = data.CourseName;
		}
		this.initializeChapters(data.chapters || data.Chapters || []);
		this.initializeVideos(data.videos || data.Videos || []);
	}

	public initializeChapters(chapters?: any) {
		if (chapters) {
			for (let i = 0; i < chapters.length; i++) {
				this.chapters.push(new Chapter(chapters[i]));
			}
		} else {
			for (let i = 0; i < 1; i++) {
				this.chapters.push(new Chapter());
			}
		}
	}
	public initializeVideos(videos?: any) {
		if (videos) {
			for (let i = 0; i < videos.length; i++) {
				this.videos.push(new Video(videos[i]));
			}
		} else {
			for (let i = 0; i < 1; i++) {
				this.videos.push(new Video());
			}
		}
	}

	public static forAPI(course: Course) {
		return {
			ID: course.id,
			Label: course.label,
			Key: course.key,
			Category: course.category,
			Description: course.description,
			DefaultCourse: course.default,
			Active: course.active,
			Length: course.length,
			TalkAboutDescription: course.talkAboutDescription,
			Chapters: course.chapters,
			CourseImage: course.courseImage,
		};
	}

	public static forAPIFormData(course: Course) {
		// 	return {
		// 		ID: course.id,
		// 		Label: course.label,
		// 		Key: course.key,
		// 		Length: course.length,
		// 		CourseListing: course.courseListing
		// 	};

		const formData: FormData = new FormData();
		course.id && formData.append("id", course.id.toString());
		formData.append("key", course.key);
		formData.append("category", course.category.toString());
		formData.append("label", course.label);
		formData.append("length", course.length);
		formData.append("talkAboutDescription", course.talkAboutDescription);

		course.chapters.forEach((courseItem) => {
			formData.append("courseListing[]", JSON.stringify(courseItem));
		});

		return formData;
	}
}

export class Chapter {
	id: number;
	name: string;
	label: string;
	length: string;
	order: number;
	isActive: number;
	code: string;
	description: string;
	videos: Video[] = [];

	constructor(data?: any) {
		if (data) {
			this.id = data.id || data.ID;
			this.name = data.name || data.Name;
			this.label = data.Label || data.label;
			this.length = data.Length || data.length;
			this.order = data.order || data.Order;
			this.isActive = data.isActive || data.IsActive;
			this.code = data.code || data.Code;
			this.description = data.Description || data.description;
		}
		this.initializeVideos(data.videos || data.Videos || []);
	}

	public initializeVideos(videos?: any) {
		if (videos) {
			for (let i = 0; i < videos.length; i++) {
				this.videos.push(new Video(videos[i]));
			}
		} else {
			for (let i = 0; i < 1; i++) {
				this.videos.push(new Video());
			}
		}
	}
}

export class Video {
	id: number;
	label: string;
	length: string;
	image: string;
	imageUpload: fileUpload;
	media: string;
	mediaUpload: fileUpload;
	captionFile: string;
	captionFileUpload: fileUpload;
	audioFile: string;
	audioFileUpload: fileUpload;
	forceVideo: number;
	isCompleted: boolean;
	description: string;
	attachments: Attachment[] = [];
	links: string[];
	courseProgress: any;
	rating: any;
	videoRating: number = 0;

	constructor(data?: any) {
		if (data) {
			this.id = data.ID || data.id;
			this.label = data.Label || data.label || data.videoTitle;
			this.length = data.Length || data.length || data.videoDuration;
			this.image = data.Image || data.image || data.videoImage;
			this.media = data.Media || data.media || data.videoFile;
			this.captionFile =
				data.CaptionFile || data.captionFile || data.captionfile;
			this.audioFile = data.AudioFile || data.audioFile;
			this.forceVideo = data.ForceVideo || data.forceVideo;
			this.description = data.description || data.Description;
			this.courseProgress = data.courseProgress || {};
			this.rating = data.rating || [];
			if (this.description) {
				this.links = this.initializeLinks();
			}
			this.isCompleted = data.IsCompleted;
			this.imageUpload = new fileUpload(
				data.ImageUpload || data.imageUpload
			);
			this.mediaUpload = new fileUpload(
				data.MediaUpload || data.mediaUpload
			);
			this.captionFileUpload = new fileUpload(
				data.CaptionFileUpload || data.captionFileUpload
			);
			this.audioFileUpload = new fileUpload(
				data.AudioFileUpload || data.audioFileUpload
			);
		} else {
			this.imageUpload = new fileUpload({});
			this.mediaUpload = new fileUpload({});
			this.captionFileUpload = new fileUpload({});
			this.audioFileUpload = new fileUpload({});
		}
		this.initializeAttachments(data.Attachments || data.attachments || []);
	}

	public initializeAttachments(attachments?: any) {
		if (attachments) {
			for (let i = 0; i < attachments.length; i++) {
				this.attachments.push(new Attachment(attachments[i]));
			}
		} else {
			for (let i = 0; i < 1; i++) {
				this.attachments.push(new Attachment());
			}
		}
	}

	public initializeLinks(): any[] {
		let links = [];

		const Token = {
			"[mindfulnessCourse]": {
				link: "/app/series-listing/mindfulness/128",
				label: "theory.courses.links.mindfulnessCourse",
			},
			"[moodcheckLink]": {
				link: "/app/load/moodcheck",
				label: "theory.courses.links.moodcheckLink",
			},
			"[zenRoomLink]": {
				link: "/app/practice/zen-room",
				label: "theory.courses.links.zenRoomLink",
			},
			"[cognitiveThoughtsLink]": {
				link: "/app/practice/cd-quiz",
				label: "theory.courses.links.cognitiveThoughtsLink",
			},
			"[thoughtDiaryLink]": {
				link: "/app/practice/thoughtdiary",
				label: "theory.courses.links.thoughtDiaryLink",
			},
			"[activitySchedulerLink]": {
				link: "/app/practice/activityscheduler",
				label: "theory.courses.links.activitySchedulerLink",
			},
			"[funAchievementLink]": {
				link: "/app/practice/funachievement",
				label: "theory.courses.links.funAchievementLink",
			},
			"[roomforthePositive]": {
				link: "/app/practice/room-for-positive/listing",
				label: "theory.courses.links.roomforthePositive",
			},
		};

		const matches = this.description.match(/\[(.*?)\]/g);

		if (matches) {
			matches.forEach((match) => {
				if (Token.hasOwnProperty(match)) {
					Token[match].label =
						TranslationServiceHelper.translateLabel(
							Token[match].label
						);
					links.push(Token[match]);
					this.description = this.description.replace(match, "");
				}
			});
		}

		return links;
	}
}

export class Attachment {
	id: number;
	title: string;
	attachment: string;
	fileUpload: string;
	fileFilename: string;
	filePath: string;
	fileFullPath: string;

	/*
	 * Different cases here should go away and we should stick to a standard response
	 * for all course data
	 */
	constructor(data?: any) {
		if (data) {
			this.id = data.ID || data.id;
			this.title = data.Title || data.title;
			this.attachment = data.Attachment || data.attachment;
			this.filePath = data.FilePath || data.filePath;
			this.fileFullPath = data.FileFullPath || data.FullFilePath;
		}
	}
}

export class fileUpload {
	fileUpload: string;
	fileFilename: string;

	constructor(data: any) {
		if (data) {
			this.fileUpload = data.FileUpload || data.fileUpload;
			this.fileFilename = data.fileFilename || data.fileFilename;
		}
	}
}
