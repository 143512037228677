import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'app/lib/user.service';
import { Organization } from 'app/models/organization';
import { User } from 'app/models/user';

@Component({
    selector: 'dashboard-executive',
    templateUrl: './dashboard-executive.component.html',
    styleUrls: ['./dashboard-executive.component.scss'],
})
export class DashboardExecutiveComponent implements OnInit {
    @Input() organization?: Organization;
    @Input() orgId?: number;
    user: User;
    constructor(private userService: UserService) {
        this.user = this.userService.getUser();
    }

    ngOnInit() {}
}
