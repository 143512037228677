<div class="btn-container" *ngIf="showButton">
    <button
        [disabled]="isExportInProgress | async"
        [ngClass]="{ 'export-in-progress': isExportInProgress | async }"
        class="btn btn-primary btn-with-icon"
        (click)="downloadReportAsPDF()">
        <svg-icon
            *ngIf="!(isExportInProgress | async)"
            icon="export"></svg-icon>
        {{ (isExportInProgress | async) ? 'Downloading...' : 'Export Report' }}
    </button>
</div>
<div id="reportContainer"></div>
