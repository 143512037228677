import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'app/models/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class PowerbiService {
    private exportInProgress = new BehaviorSubject<boolean>(false);
    exportInProgress$ = this.exportInProgress.asObservable();
    constructor(
        private http: HttpClient,
        private api: ApiService
    ) {}

    setExportState(state: boolean) {
        this.exportInProgress.next(state);
    }

    getExportState(): boolean {
        return this.exportInProgress.value;
    }

    getEmbedToken(payload): Observable<any> {
        return this.api.get('powerbi/accesstoken', payload);
    }

    createFilter(table: string, column: string, values: any[]) {
        return {
            $schema: 'http://powerbi.com/product/schema#basic',
            target: { table, column },
            operator: 'In',
            values,
        };
    }

    generateEmbedUrl(tokenObject: any, user: User): string {
        let baseUrl = 'https://app.powerbi.com/reportEmbed';
        const queryParams = [
            `reportId=${encodeURIComponent(tokenObject?.reportID)}`,
            `groupId=${encodeURIComponent(tokenObject?.groupID)}`,
            `filter=${encodeURIComponent(`Parameter_QuizID eq ${user.mentalWellBeingFormId}`)}`,
            `filter=${encodeURIComponent(`Parameter_ThoughtDistortionQuizID eq ${user.thoughtDistortionQuizId}`)}`,
        ];

        return `${baseUrl}?${queryParams.join('&')}`;
    }

    exportPdf(tokenObject: any, bookmarkState: any): Promise<any> {
        const exportUrl = `https://api.powerbi.com/v1.0/myorg/groups/
		${tokenObject?.groupID}/reports/${tokenObject?.reportID}/ExportTo`;
        const body = {
            format: 'PDF',
            powerBIReportConfiguration: {
                defaultBookmark: {
                    state: bookmarkState.state,
                },
            },
        };
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${tokenObject?.accessToken}`)
            .set('Content-Type', 'application/json');

        return this.http.post(exportUrl, body, { headers }).toPromise();
    }

    checkReportGenerated(
        exportId: string,
        tokenObject: any,
        isCompleted = false
    ): Promise<any> {
        const exportUrl = `https://api.powerbi.com/v1.0/myorg/groups/${tokenObject?.groupID}/reports/${tokenObject?.reportID}/exports/${exportId}${isCompleted ? '/file' : ''}`;
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${tokenObject?.accessToken}`)
            .set(
                'Content-Type',
                isCompleted ? 'application/pdf' : 'application/json'
            );
        let responseType: any = isCompleted ? 'blob' : 'json';

        return this.http
            .get(exportUrl, { headers, responseType: responseType })
            .toPromise();
    }
}
